import { Survey } from '../models';
import { instance } from './base';

/**
 * Get Survey
 */
const getSurvey = async (id: string) => {
  try {
    const response = await instance.get(`/survey-veeba/${id}`);

    if (!response.data.data) return null;

    const survey = response.data.data;

    //random sort answers list if flag rotation truthy
    (survey.template.template.questions as Survey).questions.forEach(
      question => {
        if (!question.rotation) {
          return;
        }

        if (question.answers[0].type === 'dropdown_multiple_single') {
          question.answers[0].list_variant = question.answers[0].list_variant
            ?.map(value => ({
              value,
              sort: Math.random()
            }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);

          return question;
        }

        question.answers[0].list.sort((a, b) => {
          if ([a.rotation, b.rotation].some(condition => condition === false)) {
            return 0;
          }

          return Math.random() - 0.5;
        });
      }
    );

    return survey as any;
  } catch (error) {
    // http://veeba.com.ua/?utm_source=questionary
    console.log(error);
  }
};

export { getSurvey };
