import { Dependency, ListVariantDependency } from 'src/api';
import { isAllKeysExists, isExists } from '../check-value';

/**
 * Is List Variant Dependency
 */
const isListVariantDependency = (
  dependency: Dependency | undefined
): dependency is ListVariantDependency =>
  isExists(dependency) &&
  isAllKeysExists<Dependency, keyof ListVariantDependency>(dependency, [
    'answers'
  ]);

export { isListVariantDependency };
