import React, { Fragment } from 'react';
import { Range as ReactRange } from 'react-range';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { Checkbox } from '../checkbox';
import { useRangeProps } from './range.props';
import styles from './range.module.scss';

/**
 * <Range />
 */
const Range = hoc(
  useRangeProps,
  ({
    answExclusiveFlag,
    exclusive,
    checked,
    touched,
    text,
    max,
    min,
    value,
    isValueNumber,
    isError,
    checkbox,
    _onChange,
    _onCheckboxChange
  }) => (
    <Fragment>
      {exclusive &&
        <div className={styles.checkbox__container}>
          <Checkbox
          label={checkbox}
          value={checked}
          onChange={_onCheckboxChange}
      />
      <p className={styles.text}>{text}</p>
      </div>
      }
      {!exclusive &&
      <div className={styles.range}>
        <p className={styles.text2}>{text}</p>
        <ReactRange
          step={1}
          min={min}
          max={max}
          values={[isValueNumber && !answExclusiveFlag ? value : min]}
          disabled={(!!checkbox && !isValueNumber) || (!!checkbox && checked) || answExclusiveFlag}
          onChange={values => _onChange?.(values[0])}
          renderTrack={({ props, children }) => {
            const gradientPercent = isValueNumber && !answExclusiveFlag ? ((value - min) * 100) / (max - min) : 0;

            return (
              <div
                className={classNames(styles.track, {
                  [styles.error]: isError
                })}
                {...props}
                style={{
                  ...props.style,
                  background: `linear-gradient(to right, #005D7E ${gradientPercent}%, #99979E ${gradientPercent}%)`
                }}
              >
                {children}
              </div>
            );
          }}
          renderThumb={({ props }) => (
            <div
              className={styles.thumb}
              {...props}
              data-content={
                touched && !checked && !answExclusiveFlag ? (isValueNumber ? value : min) : ''
              }
              style={{
                ...props.style
              }}
            />
          )}
        />

        <div className={styles.edge}>
          <p className={styles.min}>{min}</p>
          <p className={styles.max}>{max}</p>
        </div>
      </div>
      }
      {!!checkbox && (
        <div className={styles.checkbox__container}>
          <Checkbox
            label={checkbox}
            value={answExclusiveFlag ? !answExclusiveFlag : checked}
            disabled={answExclusiveFlag}
            onChange={_onCheckboxChange}
          />
        </div>
      )}
    </Fragment>
  )
);

/**
 * <Range /> default props
 */
Range.defaultProps = {
  text: ''
};

export { Range };
