import { useLayoutEffect } from 'react';
import { useStore } from 'src/context';
import { useCurrentAnswer } from './use-current-answer.hook';
import {
  AnswersSumDependency,
  LimitSumDependency,
  ListVariantDependency,
  MultiListVariantDependency
} from 'src/api';
import {
  getObjectFromTarget,
  isAnswersSumDependency,
  isLimitSumDependency,
  isListVariantDependency,
  isListVariantDependencyNotShow,
  isMultiListVariantDependency,
  isListVariantDependencySecond,
  isObject
} from '../utils';

/**
 * Use Dependency
 */
const useDependency = () => {
  const { getAnswer } = useCurrentAnswer();

  const { current, questions, next } = useStore();

  const question = questions?.find(
    question => questions.indexOf(question) === current
  );

  let skip = false;

  //skip questions if dependency not resolved
  //need to call then question is changed. results in deps call this every render
  useLayoutEffect(() => {
    if (!question || (!question.dependency && !question.dependency_not_show && !question.dependency_second))
      return;
    
    const checkDependency = (isFirst) => {
      const dependency_base  = isFirst ? question.dependency : question.dependency_second;
      const {
        questionId,
        answers,
        answers_count,
        list_variant = ''
      } = dependency_base as MultiListVariantDependency;

      const currentAnswer = getAnswer(questionId);

      const currentAnswerAsArray = Array.isArray(currentAnswer)
        ? currentAnswer
        : [];

      const validAnswer = answers.split('|');

      const getValidAnswers = () => {
        if (list_variant) {
          return list_variant
            .split('|')
            .map(
              variant =>
                (currentAnswer as Record<string, string | number>)[variant]
            );
        }

        if (typeof currentAnswer === 'string') {
          return [currentAnswer];
        }

        return Object.keys(currentAnswer);
      };

      //limitation for count and answer
      if (answers_count && validAnswer)
      {
        console.log('length', Object.keys(getObjectFromTarget<string>(currentAnswer)).length);
        if (
          Object.keys(getObjectFromTarget<string>(currentAnswer)).length ===
          answers_count
          && 
          getValidAnswers()
          .filter(Boolean)
          .map(String)
          .some(text => validAnswer.includes(text))
        ) {
          return;
        }
        //next({ text: question.text, description: question.description, skip: true });
        skip = true;
        return;
      }

      if (list_variant) {
        const variantAnswers = list_variant
          .split('|')
          .map(
            listVariant =>
              getObjectFromTarget<string>(currentAnswer)[listVariant]
          )
          .filter(Boolean);

        if (
          variantAnswers.some(answer => validAnswer.includes(answer)) ||
          (validAnswer.some(van => van.includes('<>')) &&
            variantAnswers.some(answer =>
              validAnswer.some(
                van => van.includes('<>') && van !== '<>' + answer
              )
            ))
        )
          return;

        //next({ text: question.text, description: question.description, skip: true });
        skip= true;
        return;
      }

      if (
        typeof currentAnswer === 'number' &&
        validAnswer.includes(`${currentAnswer}`)
      )
        return;

      if (isObject(currentAnswer)) {
        const currentResult_k = Object.keys(currentAnswer);

        if (
          validAnswer.some(van => van.includes('<>')) &&
          currentResult_k.some(answer =>
            validAnswer.some(
              van => van.includes('<>') && van === '<>' + answer
            )
          )
        ) {
          //next({ text: question.text, description: question.description, skip: true });
          skip = true;
          return;
        }

        if (currentResult_k.some(answer => validAnswer.includes(`${answer}`)))
          return;
      }

      const currentAnswers =
        typeof currentAnswer === 'string'
          ? currentAnswer.split('|')
          : currentAnswerAsArray;

      if (currentAnswers.some(answer => validAnswer.includes(`${answer}`)))
      return;
      

      //next({ text: question.text, description: question.description, skip: true });
      skip = true;
      return;
    }

    if([isMultiListVariantDependency, isListVariantDependency].some(fn =>
      fn(question.dependency)
    ) && !isLimitSumDependency(question.dependency) && !isListVariantDependencySecond(question.dependency_second))
    {
      checkDependency(true);
      if(skip)
      next({ text: question.text, description: question.description, skip: true });
    }

    if(isListVariantDependencySecond(question.dependency_second))
    {
      checkDependency(true);
      if (question.dependency_second.type === 'and' || question.dependency_second.type === undefined) { //and - both condition will not initialize next
        if(skip)
        next({ text: question.text, description: question.description, skip: true });
        checkDependency(false);
        if(skip)
        next({ text: question.text, description: question.description, skip: true });
      }
      else { //or - one of condition (dependency or dependency_second) will not initialize next
        if (skip) //if prev dependency skip = true check dependency_second
        {
          skip = false;
          checkDependency(false);
          if(skip)
          next({ text: question.text, description: question.description, skip: true });
        }
        // if prev dependency skip = false, then show message
      }
    }

    if(isListVariantDependencyNotShow(question.dependency_not_show))
    {
      const {
        questionId,
        answers,
        answers_count,
        list_variant = ''
      } = question.dependency_not_show as ListVariantDependency;

      const answersList = answers.split('|');
      console.log('11', 11);

      const currentAnswer = getAnswer(questionId);

      const getValidAnswers = () => {
        if (list_variant) {
          return list_variant
            .split('|')
            .map(
              variant =>
                (currentAnswer as Record<string, string | number>)[variant]
            );
        }

        if (typeof currentAnswer === 'string') {
          return [currentAnswer];
        }

        return Object.keys(currentAnswer);
      };

      //limitation for count and answer
      if (answers_count && answersList)
      {
        console.log('length', Object.keys(getObjectFromTarget<string>(currentAnswer)).length);
        if (
          Object.keys(getObjectFromTarget<string>(currentAnswer)).length ===
          answers_count
          && 
          getValidAnswers()
          .filter(Boolean)
          .map(String)
          .some(text => answersList.includes(text))
        ) {
          next({ text: question.text, description: question.description, skip: true });
          return;
        }
        return;
      }

      if (
        !getValidAnswers()
          .filter(Boolean)
          .map(String)
          .some(text => answersList.includes(text))
      )
        return;

      next({ text: question.text, description: question.description, skip: true });
      return;
    }

    if(isLimitSumDependency(question.dependency))
    {
      const {
        questionId,
        type,
        limit_sum,
        limit
      } =  question.dependency as LimitSumDependency;

      const currentAnswer = getAnswer(questionId);

      let condition;

      if (!isNaN(limit_sum as number)) {
        const sum =
          Object.values(getObjectFromTarget<number | string>(currentAnswer))
            .map(Number)
            .filter(Number)
            .reduce((sum, current) => sum + current, 0) || 0;

        condition =
          type === 'more'
            ? (limit_sum as number) < sum
            : (limit_sum as number) > sum;
      }

      if (!isNaN(limit as number)) {
        const answer = ((question.dependency as unknown) as ListVariantDependency).answers;
        // @ts-ignore
        const value = +currentAnswer[answer];

        condition =
          type === 'more'
            ? (limit as number) < value
            : (limit as number) > value;
      }

      if (condition) return;

      next({ text: question.text, description: question.description, skip: true });
      return;
    }

    if(isAnswersSumDependency(question.dependency))
    {
      const { questionId } = question.dependency as AnswersSumDependency;
        const currentAnswer = getAnswer(questionId);

        const { answers_count } = question.dependency as AnswersSumDependency;

        if (
          Object.keys(getObjectFromTarget<string>(currentAnswer)).length >
          answers_count
        ) {
          return;
        }

        next({ text: question.text, description: question.description, skip: true });
        return;
    }
  }, [question]);
};

export { useDependency };
