import React from 'react';
import { Form, hoc } from 'src/core';
import { useQuestionProps } from './question.props';

/**
 * <Question />
 */
const Question = hoc(
  useQuestionProps,
  ({
    type,
    uploadType,
    question,
    isError,
    error,
    inputValue,
    nextQuestion,
    isQuestionTouched,
    list,
    Control,
    onChange
  }) =>
    !!question ? (
      <Form
        type={type}
        question={question}
        inputValue={inputValue as any}
        isError={!!isError}
        nextQuestion={nextQuestion}
      >
        <Control
          key={`${question.questionId}${type}`}
          type={type}
          list={list}
          question={question}
          onChange={onChange}
          value={inputValue}
          isError={isQuestionTouched && !!isError}
          error={error}
          uploadType={uploadType}
        />
      </Form>
    ) : null
);

export { Question };
