import React from 'react';
import classNames from 'classnames';
import { Button, ErrorMessage, hoc } from 'src/core';
import { useGeoLocationQuestionProps } from './geo-location-question.props';
import styles from './geo-location-question.module.scss';

/**
 * <GeoLocationQuestion />
 */
const GeoLocationQuestion = hoc(
  useGeoLocationQuestionProps,
  ({ isError, errorMessage, value, onClickButton }) => (
    <div>
      <Button
        className={classNames({ [styles.shared]: !!value })}
        theme='tertiary'
        onClick={onClickButton}
      >
        Поділитись геолокацією
      </Button>
      <ErrorMessage error={errorMessage} isError={isError} />
    </div>
  )
);

export { GeoLocationQuestion };
