import { useState } from 'react';
import { Answer, QuestionModel } from 'src/api';

/**
 * Use Progress Data
 */
const useProgressData = (questions: QuestionModel[]) => {
  const [current, setCurrent] = useState(0);
  const [questionsIndexes, setQuestionsIndexes] = useState<number[]>([0]);

  const setNextQuestion = (answer: Answer) => {
    const nextQuestionIndex = questions.findIndex(
      question => question.questionId === answer.next_question
    );

    if (questionsIndexes.includes(nextQuestionIndex)) {
      setCurrent(nextQuestionIndex);
      return;
    }

    setQuestionsIndexes(prev => [...prev, nextQuestionIndex]);

    return setCurrent(nextQuestionIndex);
  };

  const next = (answer?: Answer & { skip?: boolean }, navFunc?: Function) => {
    if (
      [questions[current], answer].some(item => item?.endQuestionary === true && answer?.skip === false)
    ) {
      navFunc?.();
      return;
    }

    const answerWithNextQuestion = [answer, questions[current]].find(
      item => item?.next_question
    );

    console.log('answerWithNextQuestion', answerWithNextQuestion);

    if (answerWithNextQuestion) {
      setNextQuestion(answerWithNextQuestion);
      return;
    }

    setQuestionsIndexes(prev => {
      if (questionsIndexes.includes(current + 1)) return questionsIndexes;

      if (!(answer && answer?.skip)) {
        return [...prev, current + 1];
      }

      const skipIndex = questionsIndexes.find(index => index === current);

      return [...prev.filter(index => skipIndex !== index), current + 1];
    });

    const isLast = current === questions.length - 1;
    setCurrent(isLast ? questions.length - 1 : current + 1);
  };

  const prev = () => {
    const value = questionsIndexes.indexOf(current);

    setCurrent(value !== 0 ? questionsIndexes[value - 1] : 0);
  };

  return { current, next, prev };
};

export { useProgressData };
