import React from 'react';
import classNames from 'classnames';
import { Button, hoc, PhoneInput } from 'src/core';
import { usePhoneProps } from './phone.props';
import styles from './phone.module.scss';

/**
 * <Phone />
 */
const Phone = hoc(
  usePhoneProps,
  ({ value, error, errorText, submitPhone, onChange, _onBlur }) => (
    <div className={styles.phone}>
      <div className={styles.content}>
        <div className={styles.caption}>
          Залиш, будь ласка, свій номер телефону, щоб, у разі потреби ми могли з
          тобою зв’язатись
        </div>
        <div className={styles.input_container}>
          <PhoneInput
            className={styles.input}
            value={value}
            onChange={onChange}
            isError={error}
            onBlur={_onBlur}
            error={error ? errorText : ''}
          />
          <Button
            className={classNames({
              [styles.button_disabled]:
                error || value.replaceAll(' ', '').length !== 9
            })}
            onClick={() => submitPhone(value)}
            rounded
          >
            Далі
          </Button>
        </div>
      </div>
    </div>
  )
);

export { Phone };
