import React from 'react';
import { ErrorMessage, hoc, Radio } from 'src/core';
import { useRadioTableProps } from './radio-table.props';
import styles from './radio-table.module.scss';

/**
 * <RadioTable />
 */
const RadioTable = hoc(
  useRadioTableProps,
  ({
    value,
    filteredList,
    question,
    variants,
    isError,
    error,
    onFieldChange
  }) => (
    <div>
      <div className={styles.wrapper}>
        <p className={styles.description}>
          Оберіть один варіант з кожної категорії.
        </p>
        {variants.map(({ text, comment }, index) => (
          <div key={index} className={styles.variant}>
            <p className={styles.variant_title}>{text}</p>
            <div className={styles.list}>
              {filteredList.map((list, index) => {
                const option = question.answers[0].list_variant?.find(
                  ({ text: optionText, comment_dependency, comment }) =>
                    comment &&
                    optionText === text &&
                    list.text === comment_dependency
                );

                return option ? (
                  <input
                    key={index}
                    className={styles.input}
                    value={
                      filteredList.some(list => list.text === value[text])
                        ? ''
                        : value[text]
                    }
                    onChange={event => onFieldChange(text, event.target.value)}
                  />
                ) : (
                  <Radio
                    key={index}
                    label={list.text}
                    value={value[text] === list.text}
                    question={question}
                    isError={!value[text] && isError}
                    onChange={(_, value) => onFieldChange(text, value)}
                  />
                );
              })}
            </div>
            <ErrorMessage error={error} isError={!value[text] && isError} />
          </div>
        ))}
      </div>
    </div>
  )
);

export { RadioTable };
