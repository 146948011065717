import { useMemo } from 'react';
import { MonthAnswer, QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { isValidInn } from 'src/core';
import {
  currentYear,
  maxUserAge,
  minUserAge,
  months
} from 'src/app/pages/survey/utils';
import { controlsComponents } from '../question-components';

/**
 * <Question /> props type
 */
type QuestionProps = {
  /**
   * Question data
   */
  question: QuestionModel;
  /**
   * Validation
   */
  validation: {
    error: string;
    isError: boolean;
  };
  /**
   * Set is value empty
   */
  setIsValueEmpty: (value: boolean) => void;
};

/**
 * <Question /> props
 */
const useQuestionProps = ({
  question,
  validation,
  setIsValueEmpty
}: Partial<QuestionProps>) => {
  const { changeQuestionValue, results, isQuestionTouched } = useStore();

  const { error, isError } = validation as any;
  const answer = (question as QuestionModel)?.answers?.[0];

  const nextQuestion = useMemo(() => {
    switch (answer?.type) {
      case 'birth_year': {
        const currentAnswer = results[
          (question as QuestionModel).questionId
        ] as MonthAnswer;
        const birthYear = +currentAnswer.year;
        const month = currentAnswer.month;

        const isMonthDontPass =
          months.indexOf(month) > new Date().getMonth() - 1;
        const isLessThan18 = currentYear - birthYear < minUserAge;
        const is18 = currentYear - birthYear === minUserAge;

        const isGreaterThan65 = currentYear - birthYear > maxUserAge;

        if (isGreaterThan65) {
          return { text: question?.text, endQuestionary: true };
        }

        return {
          text: question?.text,
          endQuestionary: isLessThan18 ? true : is18 ? isMonthDontPass : false
        };
      }

      default: {
        return answer?.list
          ? answer?.list.find(
              item =>
                (item.value ? item.value.toString() : item.text) ===
                results[(question as QuestionModel).questionId]
            )
          : { text: question?.text };
      }
    }
  }, [question, answer?.list, answer?.type, results, question?.text]);

  const onChange = (question: QuestionModel, value: string) => {
    const getValidationRule = () => {
      switch (answer?.type) {
        case 'phone': {
          return value.length < 10;
        }

        case 'inn': {
          return !isValidInn(value);
        }

        default: {
          return value === '';
        }
      }
    };

    const validationRule = getValidationRule();

    changeQuestionValue(question, value);
    setIsValueEmpty?.(validationRule);
  };

  const inputValue = results[(question as QuestionModel)?.questionId];
  const Control = controlsComponents[answer?.type];

  return {
    type: answer?.type,
    uploadType: answer?.uploadType,
    inputValue,
    error,
    isError,
    nextQuestion,
    list: answer?.list,
    isQuestionTouched,
    Control,
    onChange
  };
};

export { useQuestionProps };
