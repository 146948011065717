import { useEffect, useState } from 'react';
import { useStore } from 'src/context';
import { errorMessages } from './utils';
import { useDependency, useMinLimitSum } from './hooks';

/**
 * <Survey /> props
 */
const useSurveyProps = () => {
  useDependency();

  const {
    isError: isMinLimitSumError,
    errorMessage: minLimitSumError
  } = useMinLimitSum();

  const { current, questions, isQuestionAnswered, isSingleError } = useStore();

  const [isValueEmpty, setIsValueEmpty] = useState(true);

  const question = questions?.find(
    question => questions.indexOf(question) === current
  );

  const type = question?.answers[0]?.type;

  const error_text = question?.error_text ? question?.error_text : 'Щоб продовжити опитування, дай, будь ласка, відповідь на запитання';
  const error =
    (type && errorMessages[type]) || isMinLimitSumError
      ? errorMessages[type] || minLimitSumError
      : error_text;

  const validation =
    question?.required &&
    (!isQuestionAnswered || isValueEmpty || isMinLimitSumError || isSingleError)
      ? {
          error,
          isError: true
        }
      : {};

  useEffect(() => {
    window.scroll(0, 0);
  }, [question]);

  return {
    question,
    questions,
    validation,
    setIsValueEmpty
  };
};

export { useSurveyProps };
