import React, { FC } from 'react';
import classNames from 'classnames';
import { ErrorMessage } from '../error-message';
import { CommentProps } from './comment.props';
import styles from './comment.module.scss';

/**
 * <Comment />
 */
const Comment: FC<CommentProps> = ({
  className,
  classes,
  error,
  isError,
  ...props
}) => (
  <label className={classNames(classes?.root)}>
    <input
      className={classNames(styles.comment, className, classes?.textarea, {
        [styles.error]: isError
      })}
      {...props}
    />

    {error && <ErrorMessage error={error} isError={isError} />}
  </label>
);

export { Comment };
