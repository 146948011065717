import React from 'react';
import classNames from 'classnames';
import { Button, ErrorMessage, hoc } from 'src/core';
import { useButtonsQuestionProps } from './buttons-question.props';
import styles from './buttons-question.module.scss';

/**
 * <ButtonsQuestion />
 */
const ButtonsQuestion = hoc(
  useButtonsQuestionProps,
  ({ list, selected, isError, error, _onClick }) => (
    <div>
      <div className={styles.controls}>
        {list.map(({ text }, index) => (
          <Button
            className={classNames({ [styles.selected]: selected === text })}
            theme='tertiary'
            onClick={() => _onClick(text)}
            key={index}
            isError={isError}
          >
            {text}
          </Button>
        ))}
      </div>

      <ErrorMessage error={error} isError={isError} />
    </div>
  )
);

export { ButtonsQuestion };
