import React from 'react';
import InputMask from 'react-input-mask';
import { Button, ErrorMessage, hoc } from 'src/core';
import { useConfirmationEmailProps } from './confirmation.props';
import styles from './confirmation.module.scss';
import classNames from 'classnames';

/**
 * <Confirmation />
 */
const ConfirmationEmail = hoc(
  useConfirmationEmailProps,
  ({
    isSent,
    focused,
    inputRef,
    value,
    error,
    setValue,
    handleSubmit,
    _onBlur,
    _onFocus
  }) => (
    <div className={styles.confirmation}>
      <div className={styles.content}>
        <div className={styles.caption}>Введи OTP-код</div>

        <div className={styles.input_container}>
          <InputMask
            className={classNames(styles.input, {
              [styles.input_focused]: focused,
              [styles.input_error]: error
            })}
            mask='XXXXX'
            formatChars={{ X: '[0-9]' }}
            alwaysShowMask
            maskChar='X'
            onChange={event => {
              setValue(event.target.value);
            }}
            onFocus={_onFocus}
            onBlur={_onBlur}
          >
            {props => <input ref={inputRef} {...props} />}
          </InputMask>
          {error && <div className={styles.input_error_icon}>!</div>}
        </div>
        <ErrorMessage
          className={styles.error}
          isError={error}
          error={
            isSent
              ? 'Невірний код'
              : 'Щоб продовжити опитування, дайте, будь ласка, відповідь на запитання. '
          }
        />
        <Button
          className={classNames({
            [styles.button_disabled]:
              error || value.replaceAll('X', '').length !== 5
          })}
          onClick={() => handleSubmit()}
          rounded
        >
          Далі
        </Button>
      </div>
    </div>
  )
);

export { ConfirmationEmail };
