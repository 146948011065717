import { Dependency, MultiListVariantDependency } from 'src/api';
import { isAllKeysExists } from '../check-value';
import { isListVariantDependency } from './is-list-variant-dependency';

/**
 * Is Multi List Variant Dependency
 */
const isMultiListVariantDependency = (
  dependency: Dependency | undefined
): dependency is MultiListVariantDependency =>
  isListVariantDependency(dependency) &&
  isAllKeysExists<Dependency, keyof MultiListVariantDependency>(dependency, [
    'list_variant'
  ]);

export { isMultiListVariantDependency };
