import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';

/**
 * <ProgressBar /> props type
 */
type ProgressBarProps = {
  /**
   * Questions
   */
  questions: QuestionModel[];
};

/**
 * <ProgressBar /> props
 */
const useProgressBarProps = ({ questions }: Partial<ProgressBarProps>) => {
  const { pathname } = useLocation();
  const { current } = useStore();

  const paths = ['/phone', '/phone/confirmation', '/', '/summary'];

  const definingStep = paths.some(item =>
    matchPath(item, {
      path: pathname,
      exact: true
    })
  );

  const summaryStep = matchPath('/summary', {
    path: pathname,
    exact: true
  });

  const step = definingStep ? (summaryStep ? questions?.length : 0) : current;

  const width = step && questions ? ((step + 1) * 100) / questions?.length : 5;

  return {
    width
  };
};

export { useProgressBarProps };
