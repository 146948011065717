import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  FileFormats,
  FormControlProps,
  QuestionModel,
  sendMedia
} from 'src/api';
import { useStore } from 'src/context';

/**
 * <Upload /> props type
 */
type UploadProps = FormControlProps & {
  /**
   * Valid file formats
   */
  accept: FileFormats;
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: File) => void;
};

/**
 * <Upload /> props
 */
const useUploadProps = ({
  question,
  value,
  onChange
}: Partial<UploadProps>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [fileUrl, setFileUrl] = useState('');

  const { changeIsQuestionAnswered } = useStore();

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files }
    } = event;

    if (!files) return;

    sendMedia(files[0]).then(res => {
      setFileUrl(URL.createObjectURL(files[0]));

      const file = res?.data;

      if (!onChange) return;

      onChange(question as QuestionModel, file || '');
    });
  };

  useEffect(() => {
    changeIsQuestionAnswered(!!value);

    if (!value) return;

    setFileUrl(value);
  }, [value, changeIsQuestionAnswered]);

  return {
    inputRef,
    fileUrl,
    onUploadClick,
    _onChange
  };
};

export { useUploadProps };
