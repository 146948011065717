/**
 * Answer Types
 */
enum AnswerTypes {
  phone = 'phone',
  text = 'text',
  scale = 'scale',
  dropdown = 'dropdown',
  geoLocation = 'geo_location',
  email_pmi = 'email_pmi',
  text_small = 'text_small',
  inn = 'inn',
  slider = 'slider',
  radiobutton = 'radiobutton',
  radiobuttonMultiple = 'radiobutton_multiple',
  dropdownMultiple = 'dropdown_multiple',
  dropdownMultipleSingle = 'dropdown_multiple_single',
  numberMultiple = 'number_multiple',
  demicalMultiple = 'demical_multiple',
  checkbox = 'checkbox',
  checkboxNumberMultiple = 'checkbox_number_multiple',
  familiarityScalePlaceRotation = 'familiarity_scale_place_rotation',
  familiarityScaleRange = 'familiarity_scale_range',
  familiarityScaleRangeSingle = 'familiarity_scale_range_single',
  birthYear = 'birth_year',
  buttons = 'buttons',
  upload = 'upload'
}

export { AnswerTypes };
