import React from 'react';
import ReactDOM from 'react-dom';

import './styles/main.scss';
import { App } from './app';
import { Provider } from './provider';

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById('root') as HTMLElement
);
