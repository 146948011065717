import React from 'react';
import styles from './row.module.scss';

/**
 * <Row /> props
 */
type RowProps = {
  /**
   * Text
   */
  text: string;
};

/**
 * <Row />
 */
const Row: React.FC<RowProps> = ({ text, children }) => (
  <div className={styles.row}>
    <div className={styles.text}>{text}</div>
    {children}
  </div>
);

export { Row };
