import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonProps } from './button.props';
import styles from './button.module.scss';

/**
 * <Button />
 */
const Button: FC<ButtonProps> = ({
  className,
  disabled,
  children,
  type,
  theme,
  rounded,
  onClick,
  isError
}) => (
  <button
    className={classNames(
      className,
      styles.button,
      styles[`button_theme_${theme}`],
      {
        [styles.button_rounded]: rounded,
        [styles.button_error]: isError
      }
    )}
    disabled={disabled}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
);

/**
 * <Button /> default props
 */
Button.defaultProps = {
  type: 'button',
  theme: 'primary'
};

export { Button };
