import { useEffect, useLayoutEffect } from 'react';
import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { DropdownQuestionProps } from '../dropdown-question';

/**
 * <BirthYearQuestion /> props type
 */
type BirthYearQuestionProps = Omit<DropdownQuestionProps, 'value'> & {
  /**
   * Value
   */
  value:
    | {
        month: string;
        year: string;
      }
    | string;
};

/**
 * <BirthYearQuestion />
 */
const useBirthYearQuestionProps = ({
  question,
  value,
  onChange,
  ...props
}: Partial<BirthYearQuestionProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  useLayoutEffect(() => {
    if (typeof value !== 'string') return;

    onChange?.(question as QuestionModel, null!);
  }, []);

  useEffect(() => {
    changeIsQuestionAnswered(
      typeof value !== 'string' && !!value?.month && !!value?.year
    );
  }, [value]);

  return {
    value: value as any,
    onChange,
    ...props
  };
};

export { useBirthYearQuestionProps };
