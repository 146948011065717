import { QuestionProps } from '../props';
import { useFilteredVariants, useMultipleQuestion } from '../multiple';
import { useDependencyNotShow } from '../../hooks';
import { QuestionModel } from '../../../../../api';

/**
 * Radio Table Value
 */
type RadioTableValue = Record<string, string>;

/**
 * <RadioTable /> props type
 */
type RadioTableProps = QuestionProps<RadioTableValue> & {
  /**
   * List
   */
  list: {
    text: string;
  }[];
};

/**
 * <RadioTable /> props
 */
const useRadioTableProps = ({
  question,
  value,
  onChange
}: Partial<RadioTableProps>) => {
  const { variants, onFieldChange, currentResult } = useMultipleQuestion(
    {
      value: value as string,
      question: question as QuestionModel,
      onChange: onChange as any
    },
    useFilteredVariants
  );

  const filteredList = useDependencyNotShow(question?.answers[0].list);

  return {
    filteredList,
    variants,
    currentResult,
    onFieldChange
  };
};

export { useRadioTableProps };
