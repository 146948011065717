import { QuestionModel } from 'src/api';
import { useCurrentAnswer } from './use-current-answer.hook';
import { useValueAsObject } from './use-value-as-object.hook';
import { isIncludeCommentCase } from '../utils';

/**
 * Use Limit Sell Min
 */
const useLimitSellMin = (question: QuestionModel) => {
  const { getAnswer } = useCurrentAnswer();

  const limit =
    question.limit_sell_min ?? question.answers[0].limit_sell_min ?? Infinity;

  const currentResult = useValueAsObject<string | number>(
    getAnswer(question.questionId)
  );

  const keys = Object.entries(currentResult)
    .filter(([key]) => !isIncludeCommentCase(key))
    .filter(([, value]) => value < limit)
    .map(([key]) => key);

  if (!question.limit_sell_min)
    return {
      isError: false,
      keys: [],
      errorMessage: ''
    };

  return {
    isError: !!keys.length,
    keys: keys,
    errorMessage: 'Надто маленьке значення'
  };
};

export { useLimitSellMin };
