import React from 'react';
import { Router } from 'react-router-dom';
import { AppContext } from '../context';
import { hoc } from '../core';
import { useProviderProps } from './provider.props';
import { createBrowserHistory } from 'history';

/**
 * History
 */
const history = createBrowserHistory();

/**
 * <Provider />
 */
const Provider = hoc(useProviderProps, ({ store, children }) => (
  <AppContext.Provider value={store as any}>
    <Router history={history}>{children}</Router>
  </AppContext.Provider>
));

export { Provider };
