import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import { ErrorMessage, hoc } from 'src/core';
import { useInnQuestionProps } from './inn-question.props';
import classNames from 'classnames';
import styles from './inn-question.module.scss';

/**
 * <InnQuestion />
 */
const InnQuestion = hoc(
  useInnQuestionProps,
  ({ question, onChange, value, isError, error }) => (
    <Fragment>
      <InputMask
        className={classNames(styles.input, {
          [styles.input_error]: isError
        })}
        mask='__________'
        formatChars={{ _: '[0-9]' }}
        value={value}
        maskChar=''
        onChange={event => {
          onChange(question, event.target.value);
        }}
        placeholder='XXXXXXXXXX'
      />
      <ErrorMessage className={styles.error} isError={isError} error={error} />
    </Fragment>
  )
);

export { InnQuestion };
