import { useEffect } from 'react';
import { navigate, redirectUrl } from '../utils';

/**
 * Use Redirect
 */
const useRedirect = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(redirectUrl);
    }, 250000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
};

export { useRedirect };
