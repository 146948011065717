import { useRedirect } from 'src/core';

/**
 * <Finished /> props
 */
const useFinishedProps = () => {
  useRedirect();

  return {};
};

export { useFinishedProps };
