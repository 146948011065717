import { ChangeEvent } from 'react';
import { FormControlProps } from 'src/api';

/**
 * <MaskedInput /> props type
 */
type MaskedInputProps = FormControlProps & {
  /**
   * Mask
   */
  mask: string;
  /**
   * Mask char
   */
  maskChar: string;
  /**
   * Onchange
   */
  onChange?: (value: string) => void;
};

/**
 * <MaskedInput /> props
 */
const useMaskedInputProps = ({ onChange }: Partial<MaskedInputProps>) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!onChange) return;

    onChange(value);
  };

  return {
    _onChange
  };
};

export { useMaskedInputProps };
