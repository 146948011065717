import React from 'react';
import { ErrorMessage, hoc } from 'src/core';
import { monthsOptions, yearsOptions } from '../../utils';
import { DropdownQuestion } from '../dropdown-question';
import { useBirthYearQuestionProps } from './birth-year-question.props';
import styles from './birth-year-question.module.scss';

/**
 * <BirthYearQuestion />
 */
const BirthYearQuestion = hoc(
  useBirthYearQuestionProps,
  ({ list, isError, value, error, ...props }) =>
    typeof value !== 'string' ? (
      <div className={styles.wrapper}>
        <DropdownQuestion
          list={monthsOptions}
          selectProps={{
            placeholder: 'Місяць',
            isSearchable: false
          }}
          value={value?.month}
          isError={!value?.month && isError}
          skipUseEffect
          {...props}
        />

        <DropdownQuestion
          list={yearsOptions}
          selectProps={{
            placeholder: 'Рік',
            isSearchable: false
          }}
          value={value?.year}
          isError={!value?.year && isError}
          skipUseEffect
          {...props}
        />

        <ErrorMessage error={error} isError={isError} />
      </div>
    ) : null
);

export { BirthYearQuestion };
