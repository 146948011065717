import { AnswersSumDependency, Dependency } from 'src/api';
import { isAllKeysExists, isExists } from '../check-value';

/**
 * Is Answer Sum Dependency
 */
const isAnswersSumDependency = (
  dependency: Dependency | undefined
): dependency is AnswersSumDependency =>
  isExists(dependency) &&
  isAllKeysExists<Dependency, keyof AnswersSumDependency>(dependency, [
    'answers_count'
  ]);

export { isAnswersSumDependency };
