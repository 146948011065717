import React from 'react';
import { Checkbox, ErrorMessage, hoc, Range } from 'src/core';
import { useFamiliarityScaleRangeProps } from './familiarity-scale-range.props';
import styles from './familiarity-scale-range.module.scss';

/**
 * <FamiliarityScaleRange />
 */
const FamiliarityScaleRange = hoc(
  useFamiliarityScaleRangeProps,
  ({ max, min, currentValue, answExclusiveVal, isUnique, isSingleError, checkSelectErrorDub, answExclusiveFlag, checkbox, error, isError, onSliderChange }) => (
    <div>
      {Object.entries(currentValue as Record<string, number>).map(
        ([text, value]) => (
          !answExclusiveVal[text] ? (
          <div key={text} className={styles.range}>
            <Range
              text={text}
              min={min}
              max={max}
              answExclusiveFlag= {answExclusiveFlag}
              value={value === -1 ? min : value}
              checkbox={checkbox}
              isError = {(currentValue[text] === -1 && isError) || (
                currentValue[text] !== -1 && currentValue[text] !== checkbox && isUnique && isSingleError && checkSelectErrorDub(text))}
              onChange={values => onSliderChange(text, values)}
            />
            <ErrorMessage
              error={error}
              isError={(currentValue[text] === -1 && isError) || (
                currentValue[text] !== -1 && currentValue[text] !== checkbox && isUnique && isSingleError && checkSelectErrorDub(text))
              }
            />
          </div>
          ) : 
          (
            <div key={text} className={styles.range}>
            <Range
              exclusive={true}
              text={text}
              value={ 0 }
              onChange={values => onSliderChange(text, values)}
            />
            <ErrorMessage
              error={error}
              isError={currentValue[text] === -1 && isError}
            />
          </div>
          )
        )
      )}
    </div>
  )
);

export { FamiliarityScaleRange };
