import { useEffect, useLayoutEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { QuestionProps } from '../props';
import { useDependencyNotShow, useValueAsObject } from '../../hooks';
import { createValue, reorder } from './familiarity-scale-place-rotation.utils';

/**
 * Familiarity Scale Place Rotation Value
 */
type FamiliarityScalePlaceRotationValue = Record<string, number>;

/**
 * <FamiliarityScalePlaceRotation /> props type
 */
type FamiliarityScalePlaceRotationProps = QuestionProps<FamiliarityScalePlaceRotationValue>;

/**
 * <FamiliarityScalePlaceRotation /> props
 */
const useFamiliarityScalePlaceRotationProps = (
  props: Partial<FamiliarityScalePlaceRotationProps>
) => {
  const { changeIsQuestionAnswered } = useStore();

  const filteredList = useDependencyNotShow(
    (props.question as QuestionModel).answers[0].list
  );

  const [sourceList, setSourceList] = useState<{
    text: string;
    isSelected: boolean;
  }[]>([]);

  const [activeOption, setActiveOption] = useState<string | null>(null);

  const droppable = {
    source: 'source',
    target: 'target'
  };

  const currentValue = useValueAsObject(props.value, () => {
    if (typeof props.value !== 'string') return;

    props.onChange?.(props.question as QuestionModel, {});
  });

  const onDragStart = ({ draggableId }: DropResult) => {
    setActiveOption(draggableId);
  };

  const onDragEnd = ({ destination, source, draggableId }: DropResult) => {
    setActiveOption(null);

    const option = sourceList.find(
      ({ text }) => `${text}-${droppable.target}` === draggableId
    );

    if (
      !destination ||
      destination.droppableId === droppable.source ||
      (!option?.isSelected && source.droppableId === droppable.target)
    )
      return;

    const getSelectedOptions = (
      list: { text: string; isSelected: boolean }[]
    ) =>
      list
        .map((option, index) => ({ ...option, index }))
        .filter(({ isSelected }) => isSelected);

    if (destination.droppableId !== source.droppableId) {
      let newList = Array.from(sourceList);

      const option = sourceList.find(
        ({ text }) => draggableId === `${text}-${droppable.source}`
      );

      newList = newList.filter(
        ({ text }) => draggableId !== `${text}-${droppable.source}`
      );

      if (option) {
        newList.splice(destination.index, 0, {
          text: option.text,
          isSelected: true
        });
      }

      props.onChange?.(
        props.question as QuestionModel,
        createValue(getSelectedOptions(newList))
      );

      setSourceList(newList);

      return;
    }

    const newList = reorder(sourceList, source.index, destination.index);

    props.onChange?.(
      props.question as QuestionModel,
      createValue(getSelectedOptions(newList))
    );

    setSourceList(newList);
  };

  useLayoutEffect(() => {
    setSourceList(
      filteredList.map(({ text }) => ({
        text,
        isSelected: false
      }))
    );
  }, [filteredList.length]);

  useEffect(() => {
    changeIsQuestionAnswered(
      Object.keys(currentValue).length === filteredList.length
    );
  }, [props.value]);

  return {
    activeOption,
    droppable,
    sourceList,
    onDragStart,
    onDragEnd,
    ...props
  };
};

export { useFamiliarityScalePlaceRotationProps };
export type { FamiliarityScalePlaceRotationValue };
