import React from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { useRadioProps } from './radio.props';
import styles from './radio.module.scss';

/**
 * <Radio />
 */
const Radio = hoc(
  useRadioProps,
  ({ value, className, disabled, label, isError, classes, _onClick }) => (
    <div
      className={classNames(
        styles.radio,
        {
          [styles.radioDisabled]: disabled
        },
        className,
        classes?.root
      )}
      onClick={_onClick}
    >
      <div
        className={classNames(
          styles.flag,
          {
            [styles.flag_active]: value,
            [styles.flag_invalid]: isError
          },
          classes?.flag
        )}
      >
        <div
          className={classNames(styles.flag__indicator, {
            [styles.flag__indicator_active]: value
          })}
        />
      </div>

      {label && (
        <label className={classNames(styles.label, classes?.label)}>
          {label}
        </label>
      )}
    </div>
  )
);

export { Radio };
