import React from 'react';
import { Header, hoc } from 'src/core';
import { useNotFoundProps } from './not-found.props';
import styles from './not-found.module.scss';

/**
 * <NotFound />
 */
const NotFound = hoc(useNotFoundProps, ({ text }) => (
  <div className={styles.container}>
    <Header />
    <div className={styles.not_found}>
      <div className={styles.caption}>{text || 'Сторінку не знайдено'}</div>
    </div>
  </div>
));

export { NotFound };
