import { InputHTMLAttributes, useRef, useState } from 'react';

/**
 * <DemicalInput /> props type
 */
type DemicalInputProps = InputHTMLAttributes<HTMLInputElement> & {
  /**
   * Classes
   */
  classes?: {
    root?: string;
    input?: string;
  };
  /**
   * Is error
   */
  isError?: boolean;
  /**
   * Error
   */
  error?: string;
};

/**
 * <DemicalInput /> props
 */
const useDemicalInputProps = ({
  value,
  onFocus,
  onBlur
}: Partial<DemicalInputProps>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  const _onFocus = event => {
    setFocused(true);

    onFocus?.(event);
  };

  const _onBlur = event => {
    setFocused(false);

    onBlur?.(event);
  };

  return {
    value,
    inputRef,
    focused,
    _onFocus,
    _onBlur
  };
};

export { useDemicalInputProps };
