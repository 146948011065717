import { instance } from './base';

/**
 * Send Number
 */
const sendNumber = async (phoneNumber: string, evt: string) => {
  try {
    const response = await instance.post('/phone', {
      phone: phoneNumber,
      evt: evt,
      veeba: true
    });

    return response ?? null;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Check Otp
 */
const checkOtp = async (data: { phoneNumber: string; otp: string }) => {
  try {
    const { phoneNumber, otp } = data;

    const response = await instance.post('/otp', {
      phone: phoneNumber,
      otp,
      veeba: true
    });

    return response ?? null;
  } catch (error) {
    console.log(error);
  }
};

export { sendNumber, checkOtp };
