import React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import { useNumberInputProps } from './number-input.props';
import styles from './number-input.module.scss';

/**
 * <NumberInput />
 */


/*const [isnumber, isNumber] = useState(true);

const onChange = (value: string) => {
  console.log('!++++++++++++', value);
    if (!Number.isInteger(value)) {
      isNumber(false);
      console.log('!!!!!!!!!!!!!!', value);
    }
};*/

const onChange = (value: string) => {
  console.log('!++++++++++++', value);
}

const NumberInput = hoc(
  useNumberInputProps,
  ({
    inputRef,
    classes,
    error,
    isError,
    focused,
    _onBlur,
    _onFocus,
    ...props
  }) => (
    <div className={classNames(classes?.root)}>
      <input
        type="number"
        inputMode="numeric"
        //pattern="\d*"
        pattern="d*"
        ref={inputRef}
        className={classNames(styles.input, {
          [styles.focused]: focused,
          [styles.error]: isError
        })}
        onFocus={_onFocus}
        onBlur={_onBlur}
        onChange = {event => onChange?.(event.target.value)}
        {...props}
      />

      {error && <ErrorMessage error={error} isError={isError} />}
    </div>
  )
);

export { NumberInput };
