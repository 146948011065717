import { useEffect } from 'react';
import { useRedirect } from 'src/core';
import { useStore } from 'src/context';

/**
 * <Summary /> props
 */
const useSummaryProps = ({
  setSurveyIsCompleted
}: {
  setSurveyIsCompleted: (value: boolean) => void;
}) => {
  useRedirect();

  const { lastPageText } = useStore();

  const caption = lastPageText?.caption;

  useEffect(
    () => () => {
      setSurveyIsCompleted?.(true);
    },
    []
  );

  return {
    caption,
    lastPageText
  };
};

export { useSummaryProps };
