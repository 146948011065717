import { useStore } from 'src/context';
import { MonthAnswer } from 'src/api';
import { currentYear } from '../utils';

/**
 * Use User Age
 */
const useUserAge = () => {
  const { results } = useStore();

  const birthYear = +((results?.BIRTH_DATE ?? {
    year: currentYear
  }) as MonthAnswer).year;

  return currentYear - birthYear;
};

export { useUserAge };
