import { instance } from './base';

/**
 * Submit Survey
 */
const submitSurvey = ({
  survey,
  results,
  isIndividualSurvey,
  userPhone,
  userEmail
}: {
  survey: any;
  results: any;
  isIndividualSurvey: boolean;
  userPhone: any;
  userEmail: any;
}) => {
  survey.template.template.questions.questions = results;
  survey.phone = userPhone;
  survey.email = userEmail;

  console.log('userEmail', userEmail);

  instance.post('/questionary', survey);

  if (!isIndividualSurvey) return;

  instance.patch(`/survey/${survey.id}`, { completed: true });
};

export { submitSurvey };
