import React, { FC, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Header, Preloader } from 'src/core';
import { useStore } from 'src/context';
import {
  Confirmation,
  Finished,
  NotFound,
  Phone,
  Summary,
  Survey,
  Welcome,
  Email,
  ConfirmationEmail
} from './pages';
//import {Email} from './pages/email/email'
import styles from './app.module.scss';

/**
 * <App />
 */
const App: FC = () => {
  const {
    id,
    loading,
    userPhone,
    clientId,
    surveyIsCompleted,
    surveyIsNotFound,
    setSurveyIsCompleted
  } = useStore();

  if (surveyIsNotFound && !!id && !loading)
    return <NotFound text='Це опитування закінчилось!' />;

  if (!id && !loading) return <NotFound />;

  if (surveyIsCompleted && !loading)
    return userPhone || clientId ? (
      <Finished />
    ) : (
      <Fragment>
        <Header />
        <Summary />
      </Fragment>
    );

  return (
    <div>
      {loading && <Preloader />}
      <div className={styles.app}>
        <Header />
        <div className={styles.container}>
          <Switch>
            <Route
              path='/summary'
              render={() => (
                <Summary setSurveyIsCompleted={setSurveyIsCompleted} />
              )}
            />
            <Route path='/email/confirmation' component={ConfirmationEmail} />
            <Route path='/email' component={Email} />
            <Route path='/phone/confirmation' component={Confirmation} />
            <Route path='/phone' component={Phone} />
            <Route path='/survey' component={Survey} />
            <Route exact path='/' component={Welcome} />
            <Route path='*' component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export { App };
