import { List, ListVariant } from 'src/api';
import { useStore } from 'src/context';
import { isObject } from '../utils';

/**
 * Use Dependency List Answers
 */
const useDependencyListAnswers = <T extends ListVariant | List>(
  list: T[] = []
) => {
  const { results } = useStore();

  return list.filter(listItem => {
    if (!listItem.dependency) {
      return true;
    }

    const { answers = '', questionId } = listItem.dependency;

    const currentResult = results[questionId];

    if (Array.isArray(currentResult)) {
      return currentResult.some(current => current === answers);
    }

    if (isObject(currentResult)) {
      const currentResult_k = Object.keys(currentResult);

      return answers
        .split('|')
        .some(answer => currentResult_k.some(current => current === answer));
    }

    return;
  });
};

export { useDependencyListAnswers };
