import React from 'react';
import classNames from 'classnames';
import { ErrorMessage, hoc } from 'src/core';
import { DropdownQuestion } from '../../dropdown-question';
import { Row, Wrapper } from '../components';
import { useMultipleDropdownsQuestionProps } from './multiple-dropdowns-question.props';
import styles from './multiple-dropdowns-question.module.scss';

/**
 * <MultipleDropdownsQuestion />
 */
const MultipleDropdownsQuestion = hoc(
  useMultipleDropdownsQuestionProps,
  ({
    variants,
    currentResult,
    question,
    list,
    checkSelectError,
    isSingle,
    isError,
    error,
    isSingleError,
    _onChange
  }) => (
    <Wrapper>
      {variants.map(({ text }) => (
        <div key={text} style={{ width: '100%' }}>
          <Row text={text}>
            <DropdownQuestion
              value={currentResult[text]?.toString?.()}
              onChange={_onChange(text)}
              question={question}
              list={list}
              selectProps={{
                className: classNames(styles.select, {
                  [styles.select__error]: checkSelectError(text)
                }),
                isSearchable: false
              }}
              isError={
                !currentResult[text]?.toString?.() &&
                (isSingle
                  ? (isSingleError && checkSelectError(text)) || isError
                  : isError)
              }
            />
          </Row>
        </div>
      ))}

      <ErrorMessage
        className={styles.error}
        error={
          isSingle && isSingleError
            ? 'Відповіді мають бути унікальними'
            : isError
            ? error
            : ''
        }
        isError={isSingle || isError}
      />
    </Wrapper>
  )
);

export { MultipleDropdownsQuestion };
