import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../../context';
import { Answer } from '../../../api';
import { navigate } from '../../utils';
import { matchPath } from 'react-router';
import { useMemo } from 'react';

/**
 * <Navigation /> props
 */
const useNavigationProps = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    id,
    prev,
    next,
    questions,
    current,
    isQuestionAnswered,
    changeIsQuestionAnswered,
    results
  } = useStore();

  const question = questions?.find(one => questions.indexOf(one) === current);

  const nextQuestion = question?.answers[0]?.list?.find(
    item =>
      (item.value ? item.value.toString() : item.text) ===
      results[question.questionId]
  ) || { text: question?.text };

  const paths = ['/phone', '/phone/confirmation', '/', '/summary'];

  const definingStep = paths.some(item =>
    matchPath(item, {
      path: pathname,
      exact: true
    })
  );

  const lastStep = useMemo(() => {
    const answerText = results[question!?.questionId];

    const answer = question?.answers[0].list?.find(
      item => item?.text === answerText
    );

    return answer?.endQuestionary || current === questions?.length - 1;
  }, [question, results]);

  const prevDisabled = definingStep || current === 0;
  const nextDisabled =
    definingStep || (question?.required && !isQuestionAnswered) || lastStep;

  const onPrevClick = () => {
    if (prevDisabled) return;

    changeIsQuestionAnswered(true);

    prev();
  };

  const onNextClick = () => {
    if (nextDisabled) return;

    changeIsQuestionAnswered(false);

    next(nextQuestion as Answer, () =>
      navigate(`/summary/?id=${id}`, history.push)
    );
  };

  return {
    prevDisabled,
    nextDisabled,
    onPrevClick,
    onNextClick
  };
};

export { useNavigationProps };
