import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendNumber } from 'src/api';
import { navigate } from 'src/core';
import { useStore } from 'src/context';

/**
 * <Phone /> props
 */
const usePhoneProps = () => {
  const history = useHistory();

  const { id, evt, setPhoneNumber } = useStore();

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const submit = (phoneNumber: string, evt: string) => {
    setPhoneNumber(phoneNumber);
    sendNumber(phoneNumber, evt).then((res: any) => {
      const message = res?.data?.message;

      if (message !== 'ok') return;

      navigate(
        `/phone/confirmation/?id=${id}&phoneNumber=${phoneNumber}`,
        history.push
      );
    });
  };

  const onChange = (value: string) => {
    setValue(value);

    if (value.replaceAll(' ', '').length < 9) return;

    setError(false);
  };

  const _onBlur = () => {
    if (value.replaceAll(' ', '').length !== 9) return setError(true);

    setError(false);
  };

  const submitPhone = (value: string) => {
    value.replaceAll(' ', '').length === 9 &&
      submit(`380${value.replaceAll(' ', '')}`, evt);
  };

  const errorText = 'Номер телефону має містити 12 символів';

  useEffect(() => {
    if (value.replaceAll(' ', '').length !== 9) return;

    setError(false);
  }, [value]);

  return {
    value,
    error,
    onChange,
    _onBlur,
    submitPhone,
    errorText
  };
};

export { usePhoneProps };
