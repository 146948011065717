import React from 'react';
import { Textarea } from 'src/core';
import { TextQuestionProps } from './text-question.props';
import styles from './text-question.module.scss';

/**
 * <TextQuestion />
 */
const TextQuestion: React.FC<TextQuestionProps> = ({ ...props }) => (
  <div className={styles.text_question}>
    <Textarea {...props} />
  </div>
);

export { TextQuestion };
