import { useStore } from 'src/context';
import { isIncludeCommentCase } from '../utils';
import { useDependencyListVariantAnswers } from './use-dependency-list-variant-answers.hook';

/**
 * Use Min Limit Sum
 */
const useMinLimitSum = () => {
  const { current, questions, results } = useStore();

  const question = questions?.find(
    question => questions.indexOf(question) === current
  );

  const minLimitSum = question?.min_limit_sum as number;
  const listVariants = question?.answers[0].list_variant;

  const filteredListVariants = useDependencyListVariantAnswers(listVariants);

  if (isNaN(question?.min_limit_sum as number))
    return {
      isError: false,
      errorMessage: ''
    };

  const currentValue = (results[question?.questionId as string] ||
    {}) as Record<string, string>;

  const valueSum = Object.entries(currentValue)
    .filter(([key]) => !isIncludeCommentCase(key))
    .reduce((sum, [, current]) => sum + +current, 0);

  const isAnsweredAllFields =
    filteredListVariants.length === Object.keys(currentValue).length
      ? Object.values(currentValue).every(value => !!value)
      : false;

  return {
    isError: isAnsweredAllFields && minLimitSum >= valueSum,
    errorMessage: 'Оберіть хоча б для одного продукту значення більше нуля'
  };
};

export { useMinLimitSum };
