import React from 'react';
import { Link } from 'react-router-dom';
import { Button, hoc } from 'src/core';
import { useWelcomeProps } from './welcome.props';
import styles from './welcome.module.scss';

/**
 * <Welcome />
 */
const Welcome = hoc(useWelcomeProps, ({ id, caption, counter, button, description }) => (
  <div className={styles.welcome}>
    <div className={styles.content}>
      <div className={styles.caption}>{caption ? caption : 'Привiт!'}</div>
      <div className={styles.description}>
        {description ? (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          'Оцiни, будь ласка, як тобi сервiс сьогоднi'
        )}
      </div>
      <div className={styles.counter}>{counter ? `Total signatures: ${ counter}` : ''}</div>
      <div className={styles.button}>
        <Link to={`/survey/?id=${id}`}>
          <Button theme='secondary' rounded>
            {button ? button : 'Почати'}
          </Button>
        </Link>
      </div>
    </div>
  </div>
));

export { Welcome };
