import { useEffect, useState } from 'react';
import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';

/**
 * <ButtonsQuestion /> props type
 */
type ButtonsQuestionProps = {
  /**
   * Radio Group data
   */
  list: {
    text: string;
  }[];
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * Question
   */
  value: { text: string };
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: string) => void;
  /**
   * Is error?
   */
  isError?: boolean;
  /**
   * Error message
   */
  error?: string;
};

/**
 * <ButtonsQuestion /> props
 */
const useButtonsQuestionProps = ({
  value,
  question,
  onChange
}: Partial<ButtonsQuestionProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const [selected, setSelected] = useState(value?.text);

  const _onClick = (text: string) => {
    setSelected(text);

    if (!onChange) return;

    onChange(question as QuestionModel, text);
  };

  useEffect(() => {
    changeIsQuestionAnswered(!!value?.text);
  }, [value]);

  return {
    selected,
    _onClick
  };
};

export { useButtonsQuestionProps };
