import React from 'react';
import { ErrorMessage, hoc, DemicalInput } from 'src/core';
import { Row, Wrapper } from '../components';
import { useMultipleDemicalQuestionProps } from './multiple-demical-question.props';

/**
 * <MultipleNumbersQuestion />
 */
const MultipleDemicalQuestion = hoc(
  useMultipleDemicalQuestionProps,
  ({
    value,
    variants,
    limitSellErrorKeys,
    limitSellErrorMessage,
    limitSellMinErrorKeys,
    limitSellMinErrorMessage,
    currentResult,
    isLimitSumError,
    limitSumErrorMessage,
    isLimitSellError,
    isLimitSellMinError,
    isDemicalErrorField,
    demicalErrorKeys,
    isError,
    error,
    _onChange
  }) => (
    <Wrapper>
      {variants.map(({ text }) => {
        let isSellError = false;
        let sellError = '';

        if (limitSellErrorKeys.includes(text)) {
          isSellError = limitSellErrorKeys.includes(text);
          sellError = limitSellErrorMessage;
        } else if (limitSellMinErrorKeys.includes(text)) {
          isSellError = limitSellMinErrorKeys.includes(text);
          sellError = limitSellMinErrorMessage;
        }

        console.log('value[text]', value[text],'sellError', sellError, 'isError', isError , 'isLimitSumError', isLimitSumError , isLimitSellError , isLimitSellMinError)

        return (
          <Row key={text} text={text}>
            <DemicalInput
              name={text}
              value={currentResult[text]?.toString().replace(',', '.') ?? ''}
              onChange={_onChange}
              isError={value[text] && (isLimitSellError || limitSellMinErrorKeys.includes(text) || demicalErrorKeys.includes(text))}
              error={sellError}
            />
          </Row>
        );
      })}

      <ErrorMessage
        error={isLimitSumError ? limitSumErrorMessage : error}
        isError={
          isError || isLimitSumError
        }
      />
    </Wrapper>
  )
);

export { MultipleDemicalQuestion };
