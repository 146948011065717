import {
  AnswersType,
  List,
  MultiListVariantDependency,
  QuestionModel
} from 'src/api';
import { useMultipleQuestion, useMultipleSingleQuestion } from '../hooks';

/**
 * <MultipleDropdownsQuestion /> props type
 */
type MultipleDropdownsQuestionProps = {
  /**
   * Type
   */
  type: AnswersType;
  /**
   * Question
   */
  question: QuestionModel<MultiListVariantDependency>;
  /**
   * Text field list
   */
  list: List[];
  /**
   * Value
   */
  value: string;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: any) => void;
  /**
   * Is error?
   */
  isError?: boolean;
  /**
   * Error message
   */
  error?: string;
};

/**
 * <MultipleDropdownsQuestion /> props
 */
const useMultipleDropdownsQuestionProps = ({
  type,
  isError,
  question,
  value,
  onChange
}: Partial<MultipleDropdownsQuestionProps>) => {
  const { variants, onFieldChange, currentResult } = useMultipleQuestion({
    onChange: onChange as any,
    value: value as any,
    question: question as QuestionModel
  });

  const {
    isSingle,
    isSingleError,
    checkSelectError
  } = useMultipleSingleQuestion({
    type: type as AnswersType,
    currentResult,
    isError
  });

  const _onChange = (text: string) => (_: any, value: string) =>
    onFieldChange(text, value);

  return {
    variants,
    currentResult,
    question,
    checkSelectError,
    isSingle,
    isError,
    isSingleError,
    _onChange
  };
};

export { useMultipleDropdownsQuestionProps };
