import React from 'react';
import { ReactComponent as Loader } from 'src/assets/images/loader.svg';
import styles from './preloader.module.scss';

/**
 * <Preloader />
 */
const Preloader = () => (
  <div className={styles.overlay}>
    <Loader />
  </div>
);

export { Preloader };
