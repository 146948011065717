import React from 'react';
import { FileFormats } from 'src/api';
import { ErrorMessage, Upload } from 'src/core';
import { UploadQuestionProps } from './upload-question.props';
import styles from './upload-question.module.scss';

/**
 * <UploadQuestion />
 */
const UploadQuestion: React.FC<UploadQuestionProps> = ({
  value,
  onChange,
  question,
  error,
  isError,
  uploadType
}) => (
  <div className={styles.upload}>
    <Upload
      accept={uploadType === 'photo' ? FileFormats.image : FileFormats.video}
      value={value}
      onChange={onChange}
      question={question}
      isError={isError}
      error={error}
    />
    <ErrorMessage error={error} isError={isError} />
  </div>
);

export { UploadQuestion };
