import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { ErrorMessage, hoc } from 'src/core';
import { useDropdownQuestionProps } from './dropdown-question.props';
import styles from './dropdown-question.module.scss';

/**
 * <DropdownQuestion />
 */
const DropdownQuestion = hoc(
  useDropdownQuestionProps,
  ({
    selectValue,
    question,
    options,
    selectProps,
    isError,
    error,
    _onChange
  }) => (
    <div className={styles.container}>
      <Select
        placeholder={question?.answers[0].placeholder ?? ''}
        value={selectValue}
        options={options}
        onChange={_onChange}
        isSearchable
        {...selectProps}
        className={classNames(styles.select, selectProps?.className, {
          [styles.select_error]: isError
        })}
      />

      {error && <ErrorMessage error={error} isError={isError} />}
    </div>
  )
);

export { DropdownQuestion };
