import React, { FC } from 'react';
import styles from './checkbox-radio-list-wrapper.module.scss';

/**
 * <CheckboxRadioListWrapper />
 */
const CheckboxRadioListWrapper: FC = ({ children }) => (
  <ul className={styles.wrapper}>{children}</ul>
);

export { CheckboxRadioListWrapper };
