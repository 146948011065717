import React from 'react';
import { ErrorMessage, hoc, NumberInput } from 'src/core';
import { Row, Wrapper } from '../components';
import { useMultipleNumbersQuestionProps } from './multiple-numbers-question.props';

/**
 * <MultipleNumbersQuestion />
 */
const MultipleNumbersQuestion = hoc(
  useMultipleNumbersQuestionProps,
  ({
    value,
    variants,
    limitSellErrorKeys,
    limitSellErrorMessage,
    limitSellMinErrorKeys,
    limitSellMinErrorMessage,
    currentResult,
    isLimitSumError,
    limitSumErrorMessage,
    isLimitSellError,
    isLimitSellMinError,
    isNumberError,
    isNumberErrorField,
    numberErrorMessageKeys,
    numberErrorMessage,
    isQuestionError,
    isError,
    error,
    _onChange
  }) => (
    <Wrapper>
      {variants.map(({ text }) => {
        let isSellError = false;
        let message = '';

        if (limitSellErrorKeys.includes(text)) {
          isSellError = limitSellErrorKeys.includes(text);
          message = limitSellErrorMessage;
        } else if (limitSellMinErrorKeys.includes(text)) {
          isSellError = limitSellMinErrorKeys.includes(text);
          message = limitSellMinErrorMessage;
        } else if (numberErrorMessageKeys.includes(text)) {
          isSellError = numberErrorMessageKeys.includes(text);
          message = numberErrorMessage;
        }

        console.log('value[text]', value[text],'sellError', message, 'isError', isError , 'isLimitSumError', isLimitSumError , isLimitSellError , isLimitSellMinError)

        return (
          <Row key={text} text={text}>
            <NumberInput
              name={text}
              value={currentResult[text] ?? ''}
              onChange={_onChange}
              isError={value[text] && (isLimitSellError || isLimitSellMinError || numberErrorMessageKeys.includes(text))}
              error={message}
            />
          </Row>
        );
      })}
      
      
      {isQuestionError && (
                <ErrorMessage isError={isQuestionError} error={"Щоб продовжити опитування, дайте, будь ласка, відповідь на запитання."} />
              )}
    </Wrapper>
  )
);

export { MultipleNumbersQuestion };
