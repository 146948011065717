import React from 'react';
import { hoc } from '../../utils';
import { useProgressBarProps } from './progress-bar.props';
import styles from './progress-bar.module.scss';

/**
 * <ProgressBar />
 */
const ProgressBar = hoc(useProgressBarProps, ({ width }) => (
  <div className={styles.track}>
    <div className={styles.track__progress} style={{ width: `${width}%` }} />
  </div>
));

export { ProgressBar };
