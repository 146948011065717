/**
 * Navigate to url
 */
const navigate = (url: string, navFunc?: Function) => {
  if (window.location.pathname !== url) {
    return navFunc ? navFunc(url) : (window.location.href = url);
  }
};

export { navigate };
