import { AnswerWithComment } from 'src/api';
import { isAnswerComment } from './check-value';

/**
 * Get Text From Checkbox Question Value
 */
const getTextFromCheckboxQuestionValue = <T extends any = AnswerWithComment>(
  value: T
) => (!isAnswerComment(value) ? value : value.answer);

export { getTextFromCheckboxQuestionValue };
