import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { hoc } from '../../utils';
import { Button } from '../button';
import { useFormProps } from './form.props';
import styles from './form.module.scss';

/**
 * <Form />
 */
const Form = hoc(
  useFormProps,
  ({
    id,
    isError,
    question,
    last,
    mobileConfirmation,
    emailConfirmation,
    isQuestionAnswered,
    onMobileSendClick,
    onSendClick,
    onNextClick,
    children
  }) => (
    <div className={styles.form}>
      <div>{children}</div>
      <div className={styles.next}>
        {last ? (
          mobileConfirmation ? (
            <div className={styles.button}>
              <Link to={`/phone/?id=${id}`}>
                <Button
                  theme='primary'
                  onClick={onMobileSendClick}
                  disabled={isError}
                  rounded
                >
                  Далі
                </Button>
              </Link>
            </div>
          ) : ( emailConfirmation ? (
            <div className={styles.button}>
              <Link to={`/email/?id=${id}`}>
                <Button
                  theme='primary'
                  onClick={onMobileSendClick}
                  disabled={isError}
                  rounded
                >
                  Далі
                </Button>
              </Link>
            </div>
          ): (
            <Button
              theme='primary'
              onClick={onSendClick}
              disabled={isError}
              rounded
            >
              {question?.button_name ? question.button_name : "Надіслати"}
            </Button>
          ))
        ) : (
          <Button
            className={classNames({
              [styles.button_disabled]:
                question?.required && (isError || !isQuestionAnswered)
            })}
            theme='primary'
            onClick={onNextClick}
            rounded
          >
            Далі
          </Button>
        )}
      </div>
    </div>
  )
);

export { Form };
