import React from 'react';
import classNames from 'classnames';
import checkmark from '../../../assets/images/checkmark.svg';
import { hoc } from '../../utils';
import { useCheckboxProps } from './checkbox.props';
import styles from './checkbox.module.scss';

/**
 * <Checkbox />
 */
const Checkbox = hoc(
  useCheckboxProps,
  ({
    className,
    disabled,
    question,
    label,
    value,
    error,
    isError,
    onChange,
    classes,
    _onClick,
    ...props
  }) => (
    <div
      className={classNames(
        styles.checkbox,
        {
          [styles.checkbox_disabled]: disabled
        },
        className,
        classes?.root
      )}
      onClick={_onClick}
      {...props}
    >
      <div
        className={classNames(
          styles.flag,
          {
            [styles.flag_checked]: value,
            [styles.flag_invalid]: isError
          },
          classes?.flag
        )}
      >
        {value && <img src={checkmark} alt='check' />}
      </div>

      {label && (
        <div className={classNames(styles.label, classes?.label)}>{label}</div>
      )}
    </div>
  )
);

/**
 * <Checkbox /> default props
 */
Checkbox.defaultProps = {
  label: '',
  value: false,
  name: '',
  onChange: () => {},
  className: '',
  disabled: false
};

export { Checkbox };
