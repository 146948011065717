import { useFormData, useProgressData } from './hooks';

/**
 * <Provider /> props
 */
const useProviderProps = () => {
  const formData = useFormData();

  const progressData = useProgressData(formData.questions);

  return {
    store: {
      ...formData,
      ...progressData
    }
  };
};

export { useProviderProps };
