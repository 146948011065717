import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getQueryParam, navigate } from 'src/core';
import { checkOtp } from 'src/api';
import { useStore } from 'src/context';

/**
 * <Confirmation /> props
 */
const useConfirmationEmailProps = () => {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const { id, sendAnswers } = useStore();

  const [isSent, setIsSent] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);

  const _onFocus = () => {
    setFocused(true);
  };

  const _onBlur = () => {
    setFocused(false);

    if (value.replaceAll('X', '').length === 5) return;

    setError(true);
  };

  const handleSubmit = () => {
    if (value.replaceAll('X', '').length !== 5) {
      setError(true);

      return;
    }

    setError(false);

    const userEmail = getQueryParam('email');
    console.log('userEmail',userEmail);

    setIsSent(true);

    checkOtp({ phoneNumber: userEmail as string, otp: value }).then(
      (res: any) => {
        if (res) {
          const status = res.data.data.ops[0].data.status;

          if (status === '400') {
            setError(true);
          } else {
            sendAnswers();

            navigate(`/summary/?id=${id}`, history.push);
          }
        } else {
          setError(true);
        }
      }
    );
  };

  useEffect(() => {
    if (value.replaceAll('X', '').length !== 5) return;

    setError(false);
  }, [value]);

  return {
    isSent,
    focused,
    inputRef,
    value,
    error,
    setValue,
    handleSubmit,
    _onFocus,
    _onBlur
  };
};

export { useConfirmationEmailProps };
