import { createContext, useContext } from 'react';
import { QuestionModel } from 'src/api';
import { AppContextModel } from './context.model';

/**
 * Foo
 */
const foo = () => {};

/**
 * App Context Default Value
 */
const AppContextDefaultValue: AppContextModel = {
  results: {},
  questions: [] as QuestionModel[],
  validation: [] as QuestionModel[],
  survey: null,
  lastPageText: {
    counter: '',
    caption: '',
    description: '',
    button: ''
  },
  firstPageText: {
    caption: '',
    counter: '',
    description: '',
    button: ''
  },
  current: 0,
  phone: '',
  phoneNumber: '',
  clientId: '',
  id: '',
  evt: '',
  userPhone: '',
  loading: false,
  surveyIsNotFound: false,
  surveyIsCompleted: false,
  isQuestionAnswered: false,
  isQuestionTouched: false,
  isSingleError: false,
  mobileConfirmation: false,
  emailConfirmation: false,
  geoLocationType: false,
  setPhoneNumber: foo,
  setEmail: foo,
  setSurveyIsCompleted: foo,
  sendAnswers: foo,
  changeQuestionValue: foo,
  changeIsQuestionAnswered: foo,
  changeIsQuestionTouched: foo,
  changeIsSingleError: foo,
  next: foo,
  prev: foo
};

/**
 * App Context
 */
const AppContext = createContext<AppContextModel>(AppContextDefaultValue);

/**
 * Use Store
 */
const useStore = () => useContext(AppContext);

export { AppContext, useStore };
