import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { isIncludeCommentCase } from '../utils';

/**
 * Use Limit Sum
 */


const useDemicalError = (question: QuestionModel) => {
  const { results } = useStore();

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;
  function onlyDemicls(str) {
    const regex1 = /^[0-9]+([\.,][0-9]+)?$/;
    return regex1.test(str);
  }

  const keys = Object.entries(currentValue)
    .filter(([key]) => key)
    .filter(([, value]) => !onlyDemicls(value))
    .map(([key]) => key.indexOf('comment-') > -1 ? null : key);

  console.log('test', keys);

  return {
    isError: Object.values(keys).some(current => current !=null),
    keys: keys,
    errorMessage: 'Тільки чисельне значення'
  };
};

const useNumberError = (question: QuestionModel) => {
  const { results } = useStore();

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;

  function onlyNumbers(str) {
    const regex1 = /^[0-9]+$/;
    return regex1.test(str);
  }

  const keys = Object.entries(currentValue)
    .filter(([key]) => key)
    .filter(([, value]) => !onlyNumbers(value))
    .map(([key]) => key.indexOf('comment-') > -1 ? null : key);

  console.log('test', keys, 'final', Object.values(keys).some(current => current !=null), 'Object.values(keys)', Object.values(keys));
  
  return {
    isError: Object.values(keys).some(current => current !=null),
    keys: keys,
    errorMessage: 'Тільки ціле значення'
  };
};



const useLimitSum = (question: QuestionModel) => {
  const { results } = useStore();

  const limitSum =
    question.limit_sum ?? question.answers[0].limit_sum ?? Infinity;

  const currentValue = (results[question.questionId] || {}) as Record<
    string,
    string
  >;

  const valueSum = Object.entries(currentValue)
    .filter(([key]) => !isIncludeCommentCase(key))
    .reduce((sum, [, current]) => sum + +current, 0);

  return {
    isError: limitSum < valueSum,
    errorMessage: 'Надто велике сумарне значення'
  };
};

export { useLimitSum , useNumberError, useDemicalError};
