import { Dependency, ListVariantDependency } from 'src/api';
import { isAllKeysExists, isExists } from '../check-value';

/**
 * Is List Variant Dependency Not Show
 */
const isListVariantDependencyNotShow = (
  dependency_not_show: Dependency | undefined
): dependency_not_show is ListVariantDependency =>
  isExists(dependency_not_show) &&
  isAllKeysExists<Dependency, keyof ListVariantDependency>(
    dependency_not_show,
    ['answers']
  );

export { isListVariantDependencyNotShow };
