import { instance } from './base';

/**
 * Send Media
 */
const sendMedia = async (file: any) => {
  const data = new FormData();
  data.append('file', file);

  try {
    const response = await instance.post('/media', data);

    return response ?? null;
  } catch (error) {
    console.log(error);
  }
};

export { sendMedia };
