import { FamiliarityScalePlaceRotationValue } from './familiarity-scale-place-rotation.props';

/**
 * Reorder
 */
const reorder = (
  list: { text: string; isSelected: boolean }[],
  sourceIndex: number,
  destinationIndex: number
) => {
  let array = Array.from(list);
  const option = array.find((_, index) => index === sourceIndex);

  array = array.filter((_, index) => index !== sourceIndex);

  if (option) {
    array.splice(destinationIndex, 0, option);
  }

  return array;
};

/**
 * Create Value
 */
const createValue = (
  list: { text: string; isSelected: boolean; index: number }[]
) =>
  list.reduce(
    (newValue, current) => ({
      ...newValue,
      [current?.text]: current.index + 1
    }),
    {} as FamiliarityScalePlaceRotationValue
  );

export { createValue, reorder };
