import { useEffect, useState } from 'react';
import { QuestionModel, AnswersType } from 'src/api';
import { useStore } from 'src/context';
import { QuestionProps } from '../props';
import { useDependencyNotShow, useValueAsObject } from '../../hooks';
import { useMultipleSingleQuestion } from '../multiple/hooks';

/**
 * Familiarity Scale Range Value
 */
type FamiliarityScaleRangeValue = Record<string, number>;

/**
 * <FamiliarityScaleRange /> props type
 */
type FamiliarityScaleRangeProps = QuestionProps<FamiliarityScaleRangeValue>;

/**
 * <FamiliarityScaleRange /> props
 */
const useFamiliarityScaleRangeProps = (
  props: Partial<FamiliarityScaleRangeProps>
) => {
  const { type, question, onChange, value, exclusive } = props;

  const scale = (question as QuestionModel).answers[0].scale || [];
  const checkbox = (question as QuestionModel).answers[0].checkbox;
  const isUnique = (question as QuestionModel).answers[0].unique;

  const [max] = useState<number>(Math.min(scale.length, 10));
  const [min] = useState<number>(() =>
    scale
      .map(Number)
      .reduce((min, current) => Math.min(min, current))
  );

  const isSingle = type === 'familiarity_scale_range_single';

  const sliders = useDependencyNotShow(
    (question as QuestionModel).answers[0].list
  );


  const { changeIsQuestionAnswered } = useStore();

  const currentValue = useValueAsObject(value, () => {
    const defaultValue = sliders.reduce(
      (v, { text}) => ({ ...v, [text]: sliders.filter(currentValue => currentValue.exclusive === true && currentValue.text === text).length > 0
        ? false : (value[text] || -1)}),
      {} as FamiliarityScaleRangeValue
    );

    onChange?.(question as QuestionModel, defaultValue);
  });

  
  const answExclusiveVal = sliders.reduce(
        (v, currentValue ) => ({ ...v, [currentValue.text]: currentValue.exclusive ? currentValue.exclusive : false}),
        {} as FamiliarityScaleRangeValue
      );

  const answExclusiveFlag = sliders.filter(currentValue => currentValue.exclusive === true && value[currentValue.text] && value[currentValue.text] === true ).length > 0 
  ? true : false;
  

  const onSliderChange = (name: string, value: number | string ) => {
    onChange?.(question as QuestionModel, { ...currentValue, [name]: value });
  };

  const asArray = Object.entries(currentValue);
  const filtered = asArray.filter(([key, value]) => !(value === checkbox));
  const values = Object.fromEntries(filtered);
  const {
    isSingleError,
    checkSelectErrorDub
  } = useMultipleSingleQuestion({
    type: type as AnswersType,
    currentResult: values,
    isError: false
  });
  //console.log('isSingleError', isSingleError, checkSelectError);

//можно перейти на следющий вопрос, только если для всех отметено в value !=-1

isUnique ? 
useEffect(() => {
    changeIsQuestionAnswered((value.toString() !== '-1' && !Object.values(value).some(value => value === -1) && !isSingleError) || Object.values(value).some(value => value === true));
  }, [value])
  :
  useEffect(() => {
    changeIsQuestionAnswered((value.toString() !== '-1' && !Object.values(value).some(value => value === -1)) || Object.values(value).some(value => value === true));
  }, [value]);

  return {
    max,
    min,
    currentValue: !isSingle ? currentValue : { '': value || min },
    isUnique,
    isSingleError,
    checkSelectErrorDub,
    checkbox,
    answExclusiveVal,
    answExclusiveFlag,
    onSliderChange,
    ...props
  };
};

export { useFamiliarityScaleRangeProps };
