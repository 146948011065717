import React from 'react';
import Logo from '../../../assets/images/logo.png';
import styles from './header.module.scss';

/**
 * <Header />
 */
const Header = () => (
  <div className={styles.header}>
    <img src={Logo} alt='Logo' />
  </div>
);

export { Header };
