import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { ErrorMessageProps } from './error-message.props';
import styles from './error-message.module.scss';

/**
 * <ErrorMessage />
 */
const ErrorMessage: FC<ErrorMessageProps> = memo(
  ({ error, isError, className, withPadding }) =>
    isError ? (
      <div
        className={classNames(styles.error, className, {
          [styles.error_with_padding]: withPadding
        })}
      >
        {error}
      </div>
    ) : null
);

/**
 * <ErrorMessage /> default Props
 */
ErrorMessage.defaultProps = {
  withPadding: false
};

export { ErrorMessage };
