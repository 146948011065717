import React from 'react';
import classNames from 'classnames';
import arrowRight from '../../../assets/images/arrow-right.svg';
import arrowRightDisabled from '../../../assets/images/arrow-right-disabled.svg';
import { hoc } from '../../utils';
import { useNavigationProps } from './navigation.props';
import styles from './navigation.module.scss';

/**
 * <Navigation />
 */
const Navigation = hoc(
  useNavigationProps,
  ({ prevDisabled, nextDisabled, onPrevClick, onNextClick }) => (
    <div className={styles.container}>
      <div className={styles.arrow_container}>
        <img
          src={prevDisabled ? arrowRightDisabled : arrowRight}
          className={classNames(styles.arrow, styles.arrow_left)}
          onClick={onPrevClick}
          alt=''
        />
      </div>
      <div className={styles.border} />
      <div
        className={classNames(
          styles.arrow_container,
          styles.arrow_container_right
        )}
      >
        <img
          src={nextDisabled ? arrowRightDisabled : arrowRight}
          className={styles.arrow}
          onClick={onNextClick}
          alt=''
        />
      </div>
    </div>
  )
);

export { Navigation };
