/**
 * Get id from query
 */
const getQueryParam = (value: string) => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(value);
};

export { getQueryParam };
