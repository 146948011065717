import { useEffect } from 'react';
import { useStore } from 'src/context';
import { isValidInn } from 'src/core';
import { QuestionProps } from '../props';

/**
 * Inn Question Value
 */
type InnQuestionValue = number;

/**
 * <InnQuestion /> props type
 */
type InnQuestionProps = QuestionProps<InnQuestionValue, string> & {
  list: {
    text: string;
    imageUrl?: string;
  }[];
};

/**
 * <InnQuestion /> props
 */
const useInnQuestionProps = ({ value }: Partial<InnQuestionProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  useEffect(() => {
    changeIsQuestionAnswered(isValidInn(value));
  }, [value]);

  return {};
};

export { useInnQuestionProps };
