import React from 'react';
import InputMask, { Props } from 'react-input-mask';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import { usePhoneInputProps } from './phone-input.props';
import styles from './phone-input.module.scss';

/**
 * <PhoneInput />
 */
const PhoneInput = hoc(
  usePhoneInputProps,
  ({
    className,
    inputRef,
    isError,
    error,
    focused,
    validationError,
    escapedValue,
    onLabelClick,
    _onChange,
    _onFocus,
    _onBlur
  }) => (
    <div className={classNames(styles.container, className)}>
      <InputMask
        type='text'
        name='phone'
        className={classNames(styles.input, styles.input_phone, {
          [styles.input_filled]: !validationError,
          [styles.input_with_red_border]: isError,
          [styles.input_focused]: focused
        })}
        mask='__ ___ __ __'
        maskChar=''
        formatChars={{ _: '[0-9]' }}
        value={escapedValue}
        onChange={_onChange}
        onFocus={_onFocus}
        onBlur={_onBlur}
      >
        {props => <input ref={inputRef} {...props} />}
      </InputMask>
      {(focused || !!escapedValue) && (
        <p className={styles.input_text}>+38 0</p>
      )}
      <p
        className={classNames(styles.label, {
          [styles.label_error]: isError,
          [styles.label_focused]: focused || !!escapedValue
        })}
        onClick={onLabelClick}
      >
        Номер телефону
      </p>
      <ErrorMessage error={error} isError={isError} withPadding />
      {isError && <div className={styles.input_error_icon}>!</div>}
    </div>
  )
);

export { PhoneInput };
