import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AnswersType, QuestionModel, sendFingerPrint, MonthAnswer } from 'src/api';
import { navigate } from 'src/core';
import {
  currentYear,
  maxUserAge,
  minUserAge
} from 'src/app/pages/survey/utils';
import { useStore } from 'src/context';

/**
 * <Form /> props type
 */
type FormProps = {
  /**
   * If field error
   */
  isError: boolean;
  /**
   * Next question
   */
  nextQuestion?: {
    /**
     * Text
     */
    text: string;
/**
     * description
     */
    description?: string;
    /**
     * Next question
     */
    next_question?: string;
  };
  /**
   * Type
   */
  type?: AnswersType;
  /**
   * Input value
   */
  inputValue?: string;
  /**
   * Question
   */
  question?: QuestionModel;
};

/**
 * <Form /> props
 */
const useFormProps = ({
  type,
  isError,
  inputValue,
  nextQuestion,
  question
}: Partial<FormProps>) => {
  const history = useHistory();

  const {
    id,
    results,
    next,
    current,
    questions,
    mobileConfirmation,
    emailConfirmation,
    sendAnswers,
    userPhone,
    isQuestionAnswered,
    changeIsQuestionAnswered,
    changeIsQuestionTouched
  } = useStore();

  const last = useMemo(() => {
    const answerText = results[question!?.questionId];

    if (question?.answers?.some(answer => answer.type === 'birth_year')) {

      const currentAnswer = results[
        (question as QuestionModel).questionId
      ] as MonthAnswer;;

      const birthYear = +currentAnswer.year;
      const month = currentAnswer.month;
      

      return (
        currentYear - birthYear < minUserAge ||
        currentYear - birthYear > maxUserAge
      );
    }

    const answer = question?.answers[0].list?.find(
      item => item?.text === answerText
    );

    return answer?.endQuestionary || question?.endQuestionary || current === questions?.length - 1;
  }, [question, results[question!?.questionId]]);

  const sendQuestionsAnswers = () => {
    if (mobileConfirmation || emailConfirmation) return;

    sendAnswers();
  };

  const onMobileSendClick = () => {
    if (type === 'inn') {
      sendFingerPrint(userPhone!, +inputValue!);
    }

    sendQuestionsAnswers();
  };

  const onSendClick = () => {
    onMobileSendClick();

    navigate(`/summary/?id=${id}`, history.push);
  };

  const onNextClick = () => {
    changeIsQuestionTouched(true);

    if (question?.required && (isError || !isQuestionAnswered)) return;

    changeIsQuestionAnswered(false);
    changeIsQuestionTouched(false);

    if (type === 'inn') {
      sendFingerPrint(userPhone!, +inputValue!);
    }

    return next(nextQuestion, () =>
      navigate(`/summary/?id=${id}`, history.push)
    );
  };

  return {
    id,
    last,
    mobileConfirmation,
    emailConfirmation,
    isQuestionAnswered,
    onMobileSendClick,
    onNextClick,
    onSendClick
  };
};

export { useFormProps };
