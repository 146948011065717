import React from 'react';
import { ErrorMessage } from 'src/core';
import { SliderQuestionProps } from './slider-question.props';
import styles from './slider-question.module.scss';

/**
 * <SliderQuestion />
 */
const SliderQuestion: React.FC<SliderQuestionProps> = ({ error, isError }) => (
  <div className={styles.sliderQuestion}>
    <h1>Slider</h1>
    <ErrorMessage error={error} isError={isError} />
  </div>
);

export { SliderQuestion };
