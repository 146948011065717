import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';

/**
 * <PhoneInput /> props type
 */
type PhoneInputProps = FormControlProps & {
  /**
   * Change handler
   */
  onChange?: (value: any, question?: string) => void;
  /**
   * Blur handler
   */
  onBlur?: () => void;
  /**
   * Label
   */
  label?: string;
  /**
   * Question
   */
  question?: QuestionModel;
};

/**
 * <PhoneInput /> props
 */
const usePhoneInputProps = ({
  question,
  value,
  onChange,
  onBlur
}: Partial<PhoneInputProps>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { changeIsQuestionAnswered } = useStore();

  const [focused, setFocused] = useState(false);

  const escapedValue =
    value && value.indexOf('380') >= 0 ? value.slice(3) : value;

  const validationError = escapedValue.replaceAll(' ', '').length !== 9;

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!onChange) return;

    return question ? onChange(question, value) : onChange(value);
  };

  const _onFocus = () => {
    setFocused(true);
  };

  const _onBlur = () => {
    setFocused(false);

    onBlur?.();
  };

  const onLabelClick = () => {
    if (focused) return;

    inputRef.current?.focus();
  };

  useEffect(() => {
    changeIsQuestionAnswered(
      !!value && value.replaceAll(' ', '').length === 12
    );
  }, [value, changeIsQuestionAnswered]);

  return {
    inputRef,
    focused,
    validationError,
    escapedValue,
    onLabelClick,
    _onChange,
    _onFocus,
    _onBlur
  };
};

export { usePhoneInputProps };
