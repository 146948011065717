import { Dependency, LimitSumDependency } from 'src/api';
import { isAllKeysExists, isExists } from '../check-value';

/**
 * Is Limit Sum Dependency
 */
const isLimitSumDependency = (
  dependency: Dependency | undefined
): dependency is LimitSumDependency =>
  isExists(dependency) &&
  (isAllKeysExists<Dependency, keyof LimitSumDependency>(dependency, [
    'limit_sum',
    'type'
  ]) ||
    isAllKeysExists<Dependency, keyof LimitSumDependency>(dependency, [
      'limit',
      'type'
    ]));

export { isLimitSumDependency };
