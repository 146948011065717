import { useEffect, useState } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';

/**
 * <Scale /> props type
 */
type ScaleProps = FormControlProps & {
  /**
   * Radio Group list
   */
  list: {
    text: string;
  }[];
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: any) => void;
};

/**
 * <Scale /> props
 */
const useScaleProps = ({ question, value, onChange }: Partial<ScaleProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const [selected, setSelected] = useState(value);

  const _onClick = (text: string) => {
    if (!onChange) return;

    onChange(question as QuestionModel, text);
    setSelected(text);
  };

  useEffect(() => {
    changeIsQuestionAnswered(!!value);
  }, [value, changeIsQuestionAnswered]);

  return {
    selected,
    _onClick
  };
};

export { useScaleProps };
