import React from 'react';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ErrorMessage, hoc } from 'src/core';
import { useFamiliarityScalePlaceRotationProps } from './familiarity-scale-place-rotation.props';
import styles from './familiarity-scale-place-rotation.module.scss';

/**
 * <FamiliarityScalePlaceRotation />
 */
const FamiliarityScalePlaceRotation = hoc(
  useFamiliarityScalePlaceRotationProps,
  ({
    activeOption,
    isError,
    error,
    droppable,
    value,
    sourceList,
    onDragStart,
    onDragEnd
  }) =>
    typeof value !== 'string' ? (
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className={styles.table}>
          {!!sourceList.length &&
            !sourceList.every(({ isSelected }) => isSelected) && (
              <Droppable droppableId={droppable.source}>
                {provided => (
                  <ul
                    className={styles.source__column}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {sourceList
                      .filter(({ isSelected }) => !isSelected)
                      .map(({ text, isSelected }, index) => (
                        <Draggable
                          draggableId={`${text}-${droppable.source}`}
                          index={index}
                          key={text}
                        >
                          {provided => (
                            <li
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className={classNames(styles.source__option, {
                                [styles.source__option__active]:
                                  activeOption === `${text}-${droppable.source}`
                              })}
                            >
                              <p className={styles.source__text}>{text}</p>
                            </li>
                          )}
                        </Draggable>
                      ))}

                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            )}

          <Droppable droppableId={droppable.target}>
            {provided => (
              <ul
                className={styles.column}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sourceList.map(({ text, isSelected }, index) => (
                  <li className={styles.row} key={index}>
                    <p className={styles.index}>{index + 1}</p>

                    {isSelected && (
                      <Draggable
                        draggableId={`${text}-${droppable.target}`}
                        index={index}
                        key={text}
                        isDragDisabled={!isSelected}
                      >
                        {provided => (
                          <p
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={classNames(styles.text, {
                              [styles.text__active]:
                                activeOption === `${text}-${droppable.target}`
                            })}
                          >
                            {isSelected && text}
                          </p>
                        )}
                      </Draggable>
                    )}
                  </li>
                ))}

                {sourceList?.filter(({ isSelected }) => isSelected)?.length >
                  sourceList?.length - 2 && provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>

        <ErrorMessage error={error} isError={isError} />
      </DragDropContext>
    ) : null
);

export { FamiliarityScalePlaceRotation };
