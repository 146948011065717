import { isExists } from './is-exists';

/**
 * Is All keys Exists
 */
const isAllKeysExists = <T extends object, K extends string = string>(
  target: T,
  keys: K[]
) => isExists(target) && keys.every(key => target.hasOwnProperty(key));

export { isAllKeysExists };
