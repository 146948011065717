import React, { Fragment } from 'react';
import { Header, hoc } from 'src/core';
import { useFinishedProps } from './finished.props';
import styles from './finished.module.scss';

/**
 * <Finished />
 */
const Finished = hoc(useFinishedProps, () => (
  <Fragment>
    <Header />
    <div className={styles.finished}>
      <div className={styles.content}>
        <div className={styles.emojis}>
          <span role='img' aria-label='emoji'>
            👌
          </span>
          <span role='img' aria-label='emoji'>
            ❤️
          </span>
        </div>
        <div className={styles.container}>
          <div>Ти вже проходив це опитування</div>
          <div>Дякуємо!</div>
        </div>
      </div>
    </div>
  </Fragment>
));

export { Finished };
