import React from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { hoc } from '../../utils';
import { ErrorMessage } from '../error-message';
import { useMaskedInputProps } from './masked-input.props';
import styles from './masked-input.module.scss';

/**
 * <MaskedInput />
 */
const MaskedInput = hoc(
  useMaskedInputProps,
  ({ mask, value, className, isError, error, maskChar, _onChange }) => (
    <div className={classNames(className, styles.masked_input)}>
      <InputMask
        className={classNames(styles.input, {
          [styles.input_invalid]: isError
        })}
        value={value}
        mask={mask}
        alwaysShowMask
        maskChar={maskChar}
        onChange={_onChange}
      />

      <ErrorMessage error={error} isError={isError} />
    </div>
  )
);

export { MaskedInput };
