import { List } from 'src/api';
import { useUserAge } from './use-user-age.hook';

/**
 * Use Age Filtered List
 */
const useAgeFilteredList = <T extends { user_age?: number } = List>(
  list: T[]
) => {
  const age = useUserAge();

  return {
    list:
      age > 0
        ? list.filter(({ user_age }) => {
            if (!user_age) {
              return true;
            }

            return age >= user_age!;
          })
        : list
  };
};

export { useAgeFilteredList };
