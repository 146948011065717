import { useRedirect } from 'src/core';

/**
 * <NotFound /> props
 */
const useNotFoundProps = (_: Partial<{ text?: string }>) => {
  useRedirect();

  return {};
};

export { useNotFoundProps };
