import React from 'react';
import classNames from 'classnames';
import { hoc } from '../../utils';
import { useScaleProps } from './scale.props';
import styles from './scale.module.scss';

/**
 * <Scale />
 */
const Scale = hoc(useScaleProps, ({ selected, isError, list, _onClick }) => (
  <div className={styles.scale}>
    <div className={styles.hint__mobile} />
    <div className={styles.marks}>
      {list.map(({ text }, index) => (
        <div
          className={classNames(styles.mark, {
            [styles.mark__selected]: text === selected,
            [styles.mark_invalid]: isError
          })}
          key={index}
          onClick={() => _onClick(text)}
        >
          {text}
        </div>
      ))}
    </div>
    <div className={classNames(styles.hint__mobile, styles.hint__last)} />
    <div className={styles.hints}>
      <div className={styles.hint} />
      <div className={styles.hint} />
    </div>
  </div>
));

export { Scale };
