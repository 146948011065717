import React from 'react';
import { ErrorMessage, hoc, Range } from 'src/core';
import { useScaleQuestionProps } from './scale-question.props';
import styles from './scale.module.scss';

/**
 * <ScaleQuestion />
 */
const ScaleQuestion = hoc(
  useScaleQuestionProps,
  ({ max, min, checkbox, value, isError, error, _onChange }) => (
    <div className={styles.scale__question}>
      <Range
        max={max}
        min={min}
        checkbox={checkbox}
        value={value as number}
        isError={isError}
        onChange={_onChange}
      />

      <ErrorMessage error={error} isError={isError} />
    </div>
  )
);

export { ScaleQuestion };
