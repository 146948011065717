import { List, ListVariant } from 'src/api';
import { useCurrentAnswer } from './use-current-answer.hook';

/**
 * Use Dependency Not Show
 */
const useDependencyNotShow = <T extends ListVariant | List>(list: T[] = []) => {
  const { getAnswer } = useCurrentAnswer();

  return list.filter(listItem => {
    if (!listItem.dependency_not_show) {
      return true;
    }

    const {
      answers = '',
      list_variant = '',
      questionId
    } = listItem.dependency_not_show;

    const currentResult = getAnswer(questionId);
    const answersList = answers.split('|');

    const getValidAnswers = () => {
      if (list_variant) {
        return list_variant
          .split('|')
          .map(
            variant =>
              (currentResult as Record<string, string | number>)[variant]
          );
      }

      if (typeof currentResult === 'string') {
        return [currentResult];
      }

      return Object.keys(currentResult);
    };

    return !getValidAnswers()
      .filter(Boolean)
      .map(String)
      .some(text => answersList.includes(text));
  });
};

export { useDependencyNotShow };
