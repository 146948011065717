import { useEffect, useMemo } from 'react';
import { AnswersType } from 'src/api';
import { useStore } from 'src/context';

/**
 * Use Multiple Single Question Params
 */
type useMultipleSingleQuestionParams = {
  type: AnswersType;
  currentResult: { [key: string]: string };
  isError?: boolean;
};

/**
 * Use Multiple Single Question
 */
const useMultipleSingleQuestion = ({
  type,
  currentResult,
  isError
}: useMultipleSingleQuestionParams) => {
  const { changeIsQuestionAnswered, changeIsSingleError } = useStore();

  const isSingle = type === 'dropdown_multiple_single';

  const keys = Object.keys(currentResult);

  const values = Object.values(currentResult);

  const isSingleError = values.length !== new Set(values).size;

  const duplicateElements = useMemo(() => {
    const duplicateIndexes: number[] = [];

    values.map((item, index) => {
      if (values.indexOf(item) === index) return;

      duplicateIndexes.push(index);

      const element = values.find(
        (value, i) => i !== index && value === values[index]
      );

      duplicateIndexes.push(values.indexOf(element as string));
    });

    const duplicates: { name: string; value: string }[] = [];

    duplicateIndexes.map(duplicateIndex => {
      duplicates.push({
        name: keys[duplicateIndex],
        value: values[duplicateIndex]
      });
    });

    return duplicates;
  }, [currentResult]);

  const checkSelectError = (text: string) =>
    isSingle && duplicateElements.some(({ name }) => name === text);

  const checkSelectErrorDub = (text: string) =>
    duplicateElements.some(({ name }) => name === text);

  useEffect(() => {
    if (!isSingle) return;

    changeIsSingleError(isSingleError);
    changeIsQuestionAnswered(!isSingleError && !isError);
  }, [isSingle, isError, isSingleError]);

  return useMemo(
    () => ({
      isSingle,
      isSingleError,
      checkSelectError,
      checkSelectErrorDub
    }),
    [isSingle, isSingleError, duplicateElements]
  );
};

export { useMultipleSingleQuestion };
