import React from 'react';
import { hoc } from '../../utils';
import { useUploadProps } from './upload.props';
import upload from '../../../assets/images/upload.svg';
import uploadSuccess from '../../../assets/images/upload-success.svg';
import styles from './upload.module.scss';

/**
 * <Upload />
 */
const Upload = hoc(
  useUploadProps,
  ({ accept, inputRef, fileUrl, onUploadClick, _onChange }) => (
    <div className={styles.container} onClick={onUploadClick}>
      <img src={!!fileUrl ? uploadSuccess : upload} alt='Upload' />
      <p>{!!fileUrl ? 'Зображення завантажено' : 'Тисни, щоб завантажити'}</p>
      <input
        className={styles.input}
        type='file'
        accept={accept}
        ref={inputRef}
        onChange={_onChange}
      />
    </div>
  )
);

export { Upload };
