import { List, QuestionModel } from 'src/api';
import { NamedProps } from 'react-select/base';
import { QuestionProps } from '../props';
import { useStore } from 'src/context';
import { useDependencyNotShow } from '../../hooks';
import { useEffect, useMemo } from 'react';
import { createOption } from './dropdown-question.utils';

/**
 * DropdownQuestionValue
 */
type DropdownQuestionValue = string;

/**
 * <DropdownQuestion /> props type
 */
type DropdownQuestionProps = QuestionProps<DropdownQuestionValue> & {
  /**
   * List
   */
  list: List[];
  /**
   * Select Props
   */
  selectProps?: Omit<NamedProps, 'onChange' | 'value' | 'options'>;
  /**
   * Skip Use Effect
   */
  skipUseEffect?: boolean;
};

/**
 * <DropdownQuestion /> props
 */
const useDropdownQuestionProps = ({
  question,
  list,
  value,
  skipUseEffect,
  onChange
}: Partial<DropdownQuestionProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const filteredList = useDependencyNotShow(list);

  const options: {
    label: string;
    value: DropdownQuestionValue;
  }[] = useMemo(() => filteredList.map(createOption), [list]);

  const selectValue = useMemo(
    () => options.find(option => option.value === value) ?? null,
    [value, options]
  );

  const _onChange = (option: any) => {
    onChange?.(question as QuestionModel, !option ? '' : option?.value);
  };

  useEffect(() => {
    if (skipUseEffect) return;

    changeIsQuestionAnswered(!!value?.length);
  }, [value]);

  return {
    selectValue,
    options,
    _onChange
  };
};

export { useDropdownQuestionProps };
export type { DropdownQuestionProps }
