import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Checkbox, Comment, ErrorMessage, hoc } from 'src/core';
import {
  CheckboxRadioListItemWrapper,
  CheckboxRadioListWrapper
} from '../shared';
import { useCheckboxQuestionProps } from './checkbox-question.props';
import styles from './checkbox-question.module.scss';

/**
 * <CheckboxQuestion />
 */
const CheckboxQuestion = hoc(
  useCheckboxQuestionProps,
  ({
    isBigList,
    isError,
    value,
    question,
    error,
    filteredList,
    commentValues,
    valuesWithComment,
    valuesWithoutComment,
    onCommentChange,
    onCheckboxClick
  }) =>
    value !== 'string' ? (
      <Fragment>
        <CheckboxRadioListWrapper>
          {filteredList.map(({ text, exclusive, comment }, index) => {
            const values = comment ? valuesWithComment : valuesWithoutComment;
            const checked = values.includes(text);

            return (
              <CheckboxRadioListItemWrapper
                key={index}
                onCheckboxRadioClick={() =>
                  onCheckboxClick(text, { exclusive, comment })
                }
                comment={
                  <React.Fragment>
                    {comment && (
                      <Comment
                        name={text}
                        value={
                          commentValues.find(({ answer }) => answer === text)
                            ?.comment ?? ''
                        }
                        onChange={event =>
                          onCommentChange(event.target.name, event.target.value)
                        }
                        disabled={!checked}
                      />
                    )}
                  </React.Fragment>
                }
              >
                <Checkbox
                  classes={{
                    flag: classNames(isBigList && styles.checkbox__small_flag),
                    label: classNames(isBigList && styles.checkbox__small_font)
                  }}
                  question={question}
                  label={text}
                  value={checked}
                  onChange={() => {}}
                  isError={isError}
                  error={error}
                />
              </CheckboxRadioListItemWrapper>
            );
          })}
        </CheckboxRadioListWrapper>
        <ErrorMessage error={error} isError={isError} />{' '}
      </Fragment>
    ) : null
);

export { CheckboxQuestion };
