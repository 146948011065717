import { useEffect } from 'react';
import { useStore } from 'src/context';
import { sendFingerPrint } from 'src/api';

/**
 * <Welcome /> props
 */
const useWelcomeProps = () => {
  const { id, firstPageText, userPhone } = useStore();

  const caption = firstPageText?.caption;
  const counter = firstPageText?.counter;
  const description = firstPageText?.description;
  const button = firstPageText?.button;

  useEffect(() => {
    if (!userPhone) return;

    sendFingerPrint(userPhone);
  }, [userPhone]);

  return {
    id,
    caption,
    counter,
    description,
    button
  };
};

export { useWelcomeProps };
