import React, { FC } from 'react';
import { CheckboxRadioListItemWrapperProps } from './checkbox-radio-list-item-wrapper.props';
import styles from './checkbox-radio-list-item-wrapper.module.scss';

/**
 * <CheckboxRadioListItemWrapper />
 */
const CheckboxRadioListItemWrapper: FC<CheckboxRadioListItemWrapperProps> = ({
  comment,
  onCheckboxRadioClick,
  children
}) => (
  <li className={styles.item}>
    <div className={styles.control} onClick={onCheckboxRadioClick}>
      {children}
    </div>
    {comment}
  </li>
);

export { CheckboxRadioListItemWrapper };
