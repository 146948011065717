import { ChangeEvent, useEffect, useState } from 'react';
import { FormControlProps, QuestionModel } from 'src/api';
import { useStore } from 'src/context';

/**
 * <Textarea /> props type
 */
type TextareaProps = FormControlProps & {
  /**
   * Question
   */
  question: QuestionModel;
  /**
   * OnChange handler
   */
  onChange: (question: QuestionModel, value: string) => void;
};

/**
 * <Textarea /> props
 */
const useTextareaProps = ({
  question,
  value,
  onChange
}: Partial<TextareaProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const [focused, setFocused] = useState(false);

  const gridTemplateColumns = `${question?.image ? '1fr 1fr' : '1fr'}`;

  const _onFocus = () => {
    setFocused(true);
  };

  const _onBlur = () => {
    setFocused(false);
  };

  const _onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) return;

    onChange(question as QuestionModel, event.target.value);
  };

  useEffect(() => {
    changeIsQuestionAnswered(!!value?.length);
  }, [value, changeIsQuestionAnswered]);

  return {
    focused,
    gridTemplateColumns,
    _onChange,
    _onFocus,
    _onBlur
  };
};

export { useTextareaProps };
