import React from 'react';
import styles from './wrapper.module.scss';

/**
 * <Wrapper />
 */
const Wrapper: React.FC = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export { Wrapper };
