import { isStringedNumber } from '../../utils';

/**
 * Create Object From Keys
 */
const createObjectFromKeys = (keys: string[], source: Record<string, string>) =>
  keys.reduce(
    (result, key) => ({ ...result, [key]: source[key] }),
    {} as Record<string, string>
  );

/**
 * Is More Than Zero
 */
const isMoreThanZero = (text: string | number) => +text > 0;

/**
 * Is Not Empty
 */
const isNotEmpty = (text: string) => !!text.length;

/**
 * Is Answered
 */
const isAnswered = (text: string) =>
  isStringedNumber(text) ? isMoreThanZero(text) : isNotEmpty(text);

export { isMoreThanZero, isNotEmpty, isAnswered, createObjectFromKeys };
