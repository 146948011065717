import { useEffect, useState } from 'react';
import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { QuestionProps } from '../props';

/**
 * Geo Location Question Value
 */
type GeoLocationQuestionValue = string;

/**
 * <GeoLocationQuestion /> props type
 */
type GeoLocationQuestionProps = QuestionProps<GeoLocationQuestionValue, any>;

/**
 * <GeoLocationQuestion /> props
 */
const useGeoLocationQuestionProps = ({
  value,
  question,
  onChange
}: Partial<GeoLocationQuestionProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const [errorMessage, setErrorMessage] = useState(
    'Щоб продовжити опитування, поділись геолокацією'
  );

  useEffect(() => {
    changeIsQuestionAnswered(!!value);
  }, [value]);

  const onClickButton = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const coordinates = {
            lon: position.coords.longitude.toString(),
            lat: position.coords.latitude.toString()
          };
          onChange?.(question as QuestionModel, coordinates);
        },
        () => {
          setErrorMessage(
            'Твої налаштування браузеру не дозволяють ділитися локацією. Зміни їх, будь ласка, щоб обрати найближчу точку.'
          );
        }
      );
    } else {
      setErrorMessage(
        'На жаль, в цьому браузері неможливо поділитися геолокацією.'
      );
    }
  };

  return {
    errorMessage,
    onClickButton
  };
};

export { useGeoLocationQuestionProps };
