import { useEffect, useState } from 'react';
import { QuestionModel } from 'src/api';
import { useStore } from 'src/context';
import { QuestionProps } from '../props';
import { useDependencyNotShow } from '../../hooks';

/**
 * <ScaleQuestion /> props type
 */
type ScaleQuestionProps = QuestionProps<number>;

/**
 * <ScaleQuestion /> props
 */
const useScaleQuestionProps = ({
  question,
  onChange,
  value
}: Partial<ScaleQuestionProps>) => {
  const { changeIsQuestionAnswered } = useStore();

  const answer = (question as QuestionModel)?.answers?.[0];

  const list = useDependencyNotShow(answer.list);
  const checkbox = answer.checkbox;

  const [max] = useState<number>(Math.min(list.length, 10));
  const [min] = useState<number>(() =>
    list
      .map(({ text }) => +text)
      .filter(Boolean)
      .reduce((min, current) => Math.min(min, current), 0)
  );

  const _onChange = (value: number) => {
    onChange?.(question as QuestionModel, value);
  };

  useEffect(() => {
    changeIsQuestionAnswered(!!value);
  }, [value]);

  return {
    max,
    min,
    checkbox,
    _onChange
  };
};

export { useScaleQuestionProps };
