import { List } from 'src/api';

/**
 * Create Option
 */
const createOption = ({ text, value }: List) => ({
  label: text,
  value: value?.toString?.() ?? text
});

export { createOption };
