import { useLayoutEffect } from 'react';

/**
 * Use Value As Object
 */
const useValueAsObject = <T, K = Record<string, T>>(
  value: string | string[] | K,
  cb: () => void = () => {}
) => {
  useLayoutEffect(cb, []);

  return Object.fromEntries(
    (Object.entries(typeof value !== 'string' && value) || ({} as K)) as any
  );
};

export { useValueAsObject };
